import { actionTypes } from "../../../shell/actions";
import get from "lodash/get";

const defaultState = {
  loading: false,
  error: false,
  errorMessage: '',
  inquiryPayment: {},
  digitalOrder: {},
  carTaxHistories: [],
  calculatedPrice: {},
  mobilTaxFormValues: {},
};

/**
 *
 * @param {Object} state - Redux state
 * @param {Object} response - server response
 */

function normalizeCarTaxHistory(data = []) {
  if (data.length <= 0) {
    return data;
  }

  return data.map((row) => ({
    productType: 'tax',
    createdAt: row.createdAt,
    orderId: row.orderHumanId || 'N/A',
    productCode: get(row, 'paymentToken.service', 'N/A'),
    statusOrder: row.statusOrder,
    providerStatusOrder: get(row, 'paymentToken.response.orderStatus', ''),
    amount: row.totalAmount,
  }));
}

export default function digitalProductReducers(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.LOADING_INQUIRY_PAYMENT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.LOADED_INQUIRY_PAYMENT:
      return {
        ...state,
        inquiryPayment: action.payload.response.data,
        loading: false,
      };
    case actionTypes.ERROR_LOADING_INQUIRY_PAYMENT:
      return {
        ...state,
        error: true,
        loading: false,
        errorMessage: action.payload.errorMessage,
      };
    case actionTypes.RESET_INQUIRY_PAYMENT:
      return {
        ...state,
        inquiryPayment: {},
      };
    case actionTypes.CREATING_DIGITAL_ORDER:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CREATED_DIGITAL_ORDER:
      return {
        ...state,
        digitalOrder: action.payload.response.data,
        loading: false,
      };
    case actionTypes.ERROR_CREATING_DIGITAL_ORDER:
      return {
        ...state,
        error: true,
        loading: false,
        errorMessage: action.payload.errorMessage,
      };
    case actionTypes.RESET_DIGITAL_ORDER_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: '',
      };
    case actionTypes.LOADING_CAR_TAX_HISTORIES:
    case actionTypes.LOADING_CALCULATE_PRICE:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.LOADED_CAR_TAX_HISTORIES:
      return {
        ...state,
        carTaxHistories: normalizeCarTaxHistory(get(action, 'payload.response.data.digitalProduct', [])),
        loading: false,
      };
    case actionTypes.ERROR_LOADING_CAR_TAX_HISTORIES:
      return {
        ...state,
        error: true,
        loading: false,
        errorMessage: action.payload.errorMessage,
      }
    case actionTypes.LOADED_CALCULATE_PRICE:
      return {
        ...state,
        calculatedPrice: action.payload.response.data,
        loading: false,
      };
    case actionTypes.ERROR_LOADING_CALCULATE_PRICE:
      return {
        ...state,
        error: true,
        loading: false,
        calculatedPrice: {},
        errorMessage: action.payload.errorMessage,
      };
    case actionTypes.SET_MOBIL_TAX_FORM_VALUES:
      return {
        ...state,
        loading: false,
        mobilTaxFormValues: action.payload.data,
      };
    case actionTypes.RESET_MOBIL_TAX_FORM_VALUES:
      return {
        ...state,
        loading: false,
        mobilTaxFormValues: {},
      };
    case actionTypes.RESET_PRICE:
      return {
        ...state,
        loading: false,
        calculatedPrice: {},
      };
    default:
      return state;
  }
}

import { differenceInYears } from 'date-fns';
import get from 'lodash/get';

const defaultMember = [{
  person: {
    age: 21,
    gender: 'MALE'
  },
  relation: "",
}];

export const getPolicyMembers = (members=defaultMember, duration) => {

  const memberList = members.map(member => {
    const dob = get(member, 'person.dob');
    const relation = get(member, 'relation', get(member, 'person.relation'));
    
    const memberObj = {
      age: dob
        ? differenceInYears(new Date(), new Date(dob)).toString()
        : get(member, 'person.age', '').toString(),
      gender: get(member, 'person.gender', '').toString(),
      is_children: JSON.stringify(relation === 'DP - Anak'),
      ...duration ? { duration } : {},
      ...dob ? { dob } : {},
    };
    
    return memberObj;
  });

  return memberList.sort((a, b) => b.age - a.age);
};

export const memberInformation = {
  mainInsured: {
    type: 'INSURED',
    isChildren: false,
    relation: '',
  },
  partner: {
    type: 'INSURED',
    isChildren: false,
    relation: 'SP - Pasangan',
  },
  policyHolder: {
    type: 'HOLDER',
    isChildren: false,
    relation: '',
  },
  buyer: {
    type: 'BUYER',
    isChildren: false,
    isCreateUser: true,
  },
  children1: {
    type: 'INSURED',
    isChildren: true,
    relation: 'DP - Anak',
  },
  children2: {
    type: 'INSURED',
    isChildren: true,
    relation: 'DP - Anak',
  },
  children3: {
    type: 'INSURED',
    isChildren: true,
    relation: 'DP - Anak',
  },
  beneficiary1: {
    type: 'BENEFICIARY',
    isChildren: false,
  },
  beneficiary2: {
    type: 'BENEFICIARY',
    isChildren: false,
  },
  beneficiary3: {
    type: 'BENEFICIARY',
    isChildren: false,
  },
  beneficiary4: {
    type: 'BENEFICIARY',
    isChildren: false,
  },
  beneficiary5: {
    type: 'BENEFICIARY',
    isChildren: false,
  },
};

export const memberDetails = {
  'Saya Sendiri': {
    type: 'INSURED',
    isChildren: false,
  },
  'Pasangan': {
    type: 'INSURED',
    isChildren: false,
    relation: 'DP - Anak',
  },
  'Pemegang Polis': {
    type: 'HOLDER',
    isChildren: false,
  },
  'Anak 1': {
    type: 'INSURED',
    isChildren: true,
    relation: 'DP - Anak',
  },
  'Anak 2': {
    type: 'INSURED',
    isChildren: true,
    relation: 'DP - Anak',
  },
  'Anak 3': {
    type: 'INSURED',
    isChildren: true,
    relation: 'DP - Anak',
  }
};

import { actionTypes } from '../../../shell/actions';

import { leadStatus } from '../../../../shared/enums';

const deafultState = {
	loading: false,
	notificationList: [],
	unReadNotificationCount: 0,
};

const notificationTransformer = (summaryList = [], prevState) => {

	const filteredStatusForNotification = ['REJECTED', 'NO_ANSWER', 'UNASSIGNED'];
	let newInterestedLeads = [{
		isRecentlyMoved: false,
		read: false,
		lead_count: 0
	}];

	const newNotificationList = summaryList
		.filter(summary => !filteredStatusForNotification.includes(summary.status) && leadStatus[summary.status])
		.map(summary => {
			summary.read = false;
			summary.leadStatus = leadStatus[summary.status].status;
			summary.lead_count = Number(summary.lead_count);
			return summary;
		})
		.reduce((acc, item) => {
			let duplicatedIndex = acc.findIndex(accItem => accItem.leadStatus === item.leadStatus);
			return duplicatedIndex === -1
				? acc.push(item)
				: acc[duplicatedIndex].lead_count = acc[duplicatedIndex].lead_count + item.lead_count, acc
		}, []);

	if (prevState.length) {
		const updatedNotificationList = prevState.map(notification => {
			const prevStateIndex = newNotificationList.findIndex(item => item.leadStatus === notification.leadStatus);
			if (prevStateIndex === -1) {
				return notification;
			} else {
				notification.read = notification.read ? notification.read : newNotificationList[prevStateIndex].read;
				if (notification.lead_count !== newNotificationList[prevStateIndex].lead_count) {
					notification.lead_count = newNotificationList[prevStateIndex].lead_count;
					notification.read = false;
				}
				// only for interested leads handles the recently moved
				if (newNotificationList[prevStateIndex].leadStatus === 'Interested') {
					if (notification.lead_count === newNotificationList[prevStateIndex].lead_count) {
						notification.lead_count = newNotificationList[prevStateIndex].lead_count;
					} else {
						newInterestedLeads[0].lead_count = newNotificationList[prevStateIndex].lead_count;
						newInterestedLeads[0].isRecentlyMoved = true;
						notification.lead_count = notification.lead_count - newNotificationList[prevStateIndex].lead_count;
					}
				}
				return notification;
			}
		});

		const isAlreadyUpdated = updatedNotificationList.some(item => Object.keys(item).includes('isRecentlyMoved'));
		return isAlreadyUpdated ? updatedNotificationList : [...updatedNotificationList, ...newInterestedLeads];
	}

	return newNotificationList;
};

export default function notificationReducers(state = deafultState, action) {
	switch (action.type) {
		case actionTypes.LOADING_NOTIFICATION_LIST:
			return {
				...state,
				loading: true,
			};
		case actionTypes.LOADED_NOTIFICATION_LIST:
			const formattedNotificationList =
				notificationTransformer(action.payload.response.data, state.notificationList);
			return {
				...state,
				loading: false,
				notificationList: formattedNotificationList,
				unReadNotificationCount: formattedNotificationList.filter(
					notification => !notification.read && notification.leadStatus !== 'Closed' && notification.lead_count !== 0
				).length
			};
		case actionTypes.MARK_AS_READ:
			const updatedNotificationList = [...state.notificationList];
			updatedNotificationList[action.payload.index].read = true;
			return {
				...state,
				loading: false,
				notificationList: updatedNotificationList,
			};
		default:
			return state;
	}
}

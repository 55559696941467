import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import NProgress from 'nprogress';

import { AGENT_CONSTANTS } from '../../../modules/agents/constants/agent.constants';

export const RouteLoader = ({ token }) => {

  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    router.events.on('routeChangeStart', () => {
      setIsLoading(true);
      NProgress.start();
      if (AGENT_CONSTANTS.getAgentByToken(token).isExternalAgent) {
        document.body.classList.add('mask__body');
      }
    });

    router.events.on('routeChangeComplete', (url) => {
      setIsLoading(false);
      window.scroll(0, 0);
      NProgress.done();
      window.dataLayer.push({
        routeName: url.split('/')[1],
        pageType: 'pageview',
        pageUrl: url,
        pageTitle: (typeof document !== 'undefined' && document.title) || '',
        event: 'pageview'
      });
      if (AGENT_CONSTANTS.getAgentByToken(token).isExternalAgent) {
        document.body.classList.remove('mask__body');
      }
    });

    router.events.on('routeChangeError', () => {
      setIsLoading(false);
      NProgress.done();
      if (AGENT_CONSTANTS.getAgentByToken(token).isExternalAgent) {
        document.body.classList.remove('mask__body');
      }
    });
  });

  return (
    <>
      {
        (
          isLoading
          && AGENT_CONSTANTS.getAgentByToken(token).isExternalAgent
        ) && (
          <div className="global__loader--wrapper">
            <div className="global__loader"></div>
          </div>
        )
      }
    </>
  );
};

import { call, put } from 'redux-saga/effects';

import { toast } from 'react-toastify';

import daysjs from 'dayjs';

import actions, { actionTypes } from '../../../shell/actions';

import { NextServerAPI } from '../../../utils';

export function* getNotificationList(action) {
	const { token } = action.payload;

	const startDate = daysjs().startOf('month');

  yield put(actions.loadingNotificationList());

  try {
    const response = yield call(NextServerAPI, {
      AuthorizationToken: `Bearer ${token}`,
      method: 'GET',
      path: `/api/leads/transaction-summary/?startDate${startDate}`,
    });

    yield put(actions.loadedNotificationList({ response }));
  } catch (e) {
		toast.error('Error Loading Notification');
  }
}

export default [
  [actionTypes.GET_NOTIFICATION_LIST, getNotificationList],
];

import React, { useEffect } from 'react';
import dynamic from 'next/dynamic';
import Router, { useRouter } from 'next/router';
import { Provider } from "react-redux";

import { ToastContainer, toast, Slide } from 'react-toastify';
import jsCookie from 'js-cookie';

import { ApolloProvider } from '@apollo/client'
import { useApollo } from '../lib/apolloClient';

import { Layout } from '../components/Layout';
import { UseScriptWrapper } from '../components/UseScriptWrapper';
import { RouteLoader } from '../client/common/components/RouteLoader/RouteLoader';

import { AuthProvider } from '../providers/Auth';
import { createGUID } from '../client/utils/guid';
import { setCookie } from "../client/utils/cookies";
import utm from '../lib/utm';
import makeStore from '../client/shell/store';
import { AGENT_CONSTANTS } from '../client/modules/agents/constants/agent.constants';

import '../styles/app.scss';

const LifepalApp = (props) => {
  const store = makeStore();
  const { query, pathname } = useRouter();

  const { Component, pageProps } = props;

  const apolloClient = useApollo(pageProps);

  const Zendesk_dependency = [
    { url: 'https://static.zdassets.com/ekr/snippet.js?key=09c3cf59-ae60-424e-a337-827ffb8feaa5', id: 'ze-snippet' }
  ];

  const registerServiceWorker = () => {
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', function () {
        navigator.serviceWorker.register('/sw.js').then(function (registration) {
          // Registration was successful
          console.log('ServiceWorker registration successful with scope: ', registration.scope);
        }, function (err) {
          // registration failed :(
          console.log('ServiceWorker registration failed: ', err);
        });
      });
    }
  };

  const importIconLibrary = () => {
    if (pathname !== '/') dynamic(()=> import('../styles/components/fonts.scss') );
  };

  useEffect(() => {
    registerServiceWorker();
    utm.create();
    setCookie('visitorId', createGUID(), 180);

    if (pathname && pathname !== '/_error') {
      setCookie('landing_page', pathname, 14);
    }

    if (window) {
      window.preventPageRefresh = (event) => {
        return event.returnValue = `Are you sure you want to leave?`;
      };
    }
    importIconLibrary();
  }, []);

  return (
    <>
      <div className="app__main">
        <AuthProvider>
          <Provider store={store}>
            <ApolloProvider client={apolloClient}>
              <Layout
                authToken={jsCookie.get('token')}
                dispatch={store.dispatch}
              >
                  <Component
                    {...pageProps}
                    query={query}
                    dispatch={store.dispatch}
                  />
                  <ToastContainer
                    hideProgressBar={true}
                    autoClose={2500}
                    position={toast.POSITION.TOP_CENTER}
                    transition={Slide}
                  />
                  {
                    AGENT_CONSTANTS.getAgentByToken(jsCookie.get('token')).isTSOSupervisor && (
                      <UseScriptWrapper URLList={Zendesk_dependency} />
                    )
                  }
              </Layout>
            </ApolloProvider>
          </Provider>
        </AuthProvider>
      </div>
      <RouteLoader token={jsCookie.get('token')} />
    </>
  )
};

export default LifepalApp;

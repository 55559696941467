import { actionTypes } from "../../../shell/actions";

const defaultState = {
  loading: false,
  error: false,
  errorMessage: '',
  quotationDetail: {},
  quotationResponse: {},
  plan: {},
  declarations: [],
  additionalBenefits: [],
  quotationHistory: {},
  existingMember: [],
  autoRangePrice: {},
  isRangePriceReverse: false,
  userProfile: {},
  updatedQuotationStatus: false,
  existingUsers: [],
  persons: [],
  relatedPlans: [],
};

function getUserProfile(dataList = []) {
  const userProfile = dataList.find(data => data.mainProfile);
  if (userProfile) {
    return userProfile;
  }

  return {};
}

function normalizeExistingMember(dataList = []) {
  let mainProfileCounter = 0;
  const existingMember = dataList.reduce((acc, data) => {
    if (data.mainProfile) {
      if (mainProfileCounter < 1) {
        acc.unshift({
          label: 'Saya Sendiri',
          value: data.id,
          data,
        });
        mainProfileCounter++;
      }
    } else {
      acc.push({
        label: data.fullName,
        value: data.id,
        data,
      });
    }
    return acc;
  }, []);

  existingMember.push({
    label: '+ Tambah Tertanggung Baru',
    value: 0,
    data: {
      id: null,
      fullName: '',
      gender: '',
      dob: '',
      address: '',
      birthPlace: '',
      citizenship: '',
      rt: '',
      rw: '',
      province: '',
      city: '',
      district: '',
      subDistrict: '',
      postalCode: '',
      idType: '',
      idNumber: '',
      isHolderMainInsured: '',
    },
  });

  return existingMember;
}

function getRangePrice(dataList = []) {
  if (!dataList.length) return {};

  const [rangePrice] = dataList;
  const copyRangePrice = Object.assign({}, rangePrice);

  copyRangePrice.lower_price = parseInt(copyRangePrice.lower_price);
  copyRangePrice.upper_price = parseInt(copyRangePrice.upper_price);

  return copyRangePrice;
}

/**
 *
 * @param {Object} state - Redux state
 * @param {Object} response - server response
 */


const normalizeExistingUsers = (dataList = []) => {
  const result = dataList.map(row => ({
    label: row.fullName,
    value: row.id,
    data: row,
  }));

  result.push({
    label: '+ Tambah Tertanggung Baru',
    value: 'new user',
    data: null,
  });

  return result;
}

export default function quotationReducers(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.LOADING_QUOTATION:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOADED_QUOTATION:
      return {
        ...state,
        quotationDetail: action.payload.response.data,
        loading: false
      };
    case actionTypes.ERROR_LOADING_QUOTATION:
      return {
        ...state,
        error: true,
        loading: false
      };
    case actionTypes.SUBMITTING_QUOTATION:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.SUBMITTED_QUOTATION:
      return {
        ...state,
        quotationResponse: action.payload.response.data,
        loading: false,
      };
    case actionTypes.ERROR_SUBMITTING_QUOTATION:
      return {
        ...state,
        error: true,
        errorMessage: action.payload.errorMessage,
        loading: false,
      };
    case actionTypes.RESET_QUOTATION_ERROR:
      return {
        ...state,
        error: false,
        errorMessage: '',
      };
    case actionTypes.RESET_QUOTATION_RESPONSE:
      return {
        ...state,
        quotationResponse: {},
      };
    case actionTypes.LOADING_PLAN_AND_PRODUCT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.LOADED_PLAN_AND_PRODUCT:
      return {
        ...state,
        loading: false,
        additionalBenefits: action.payload.additionalBenefits.data,
        declarations: action.payload.declarations.data,
        autoRangePrice: getRangePrice(action.payload.autoRangePrice.data),
        isRangePriceReverse: false,
      };
    case actionTypes.ERROR_LOADING_PLAN_AND_PRODUCT:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case actionTypes.RESET_APPLICATION_DATA:
      return {
        ...state,
        loading: false,
        error: false,
        quotationDetail: {},
        plan: {},
        additionalBenefits: [],
        declarations: [],
        autoRangePrice: {},
      }
    case actionTypes.LOADING_QUOTATION_HISTORY:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOADED_QUOTATION_HISTORY:
      return {
        ...state,
        quotationHistory: action.payload.response.data,
        loading: false
      };
    case actionTypes.ERROR_LOADING_QUOTATION_HISTORY:
      return {
        ...state,
        error: true,
        loading: false
      };
    case actionTypes.LOADING_EXISTING_MEMBER:
    case actionTypes.LOADING_EXISTING_USERS:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOADED_EXISTING_MEMBER:
      return {
        ...state,
        existingMember: normalizeExistingMember(action.payload.response.data),
        loading: false
      };
    case actionTypes.ERROR_LOADING_EXISTING_MEMBER:
      return {
        ...state,
        error: true,
        loading: false
      };
    case actionTypes.LOADING_PLAN:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOADED_PLAN:
      return {
        ...state,
        plan: action.payload.plan.data,
        loading: false
      };
    case actionTypes.ERROR_LOADING_PLAN:
      return {
        ...state,
        error: true,
        loading: false
      };
    case actionTypes.LOADING_RELATED_PLANS:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOADED_RELATED_PLANS:
      return {
        ...state,
        relatedPlans: action.payload.response.data.plans,
        loading: false
      };
    case actionTypes.ERROR_LOADING_RELATED_PLANS:
      return {
        ...state,
        error: true,
        loading: false
      };
    case actionTypes.LOADED_EXISTING_USERS:
      return {
        ...state,
        existingUsers: normalizeExistingUsers(action.payload.response.data),
        loading: false
      };
    case actionTypes.ERROR_LOADING_EXISTING_USERS:
      return {
        ...state,
        error: true,
        loading: false
      };
    case actionTypes.LOADING_USER_PROFILE:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOADED_USER_PROFILE:
      return {
        ...state,
        userProfile: getUserProfile(action.payload.userProfile.data),
        loading: false
      };
    case actionTypes.ERROR_LOADING_USER_PROFILE:
      return {
        ...state,
        error: true,
        loading: false
      };
    case actionTypes.UPDATING_STATUS:
      return {
        ...state,
        loading: true
      };
    case actionTypes.UPDATED_STATUS:
      return {
        ...state,
        updatedQuotationStatus: true,
      };
    case actionTypes.ERROR_UPDATING_STATUS:
      return {
        ...state,
        error: true,
        loading: false
      };
    case actionTypes.RESET_UPDATED_STATUS:
      return {
        ...state,
        updatedQuotationStatus: false,
      };
    case actionTypes.LOADING_BENEFITS_AND_TSI:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOADED_BENEFITS_AND_TSI:
      return {
        ...state,
        additionalBenefits: action.payload.additionalBenefits.data,
        autoRangePrice: getRangePrice(action.payload.autoRangePrice.data),
        loading: false
      };
    case actionTypes.ERROR_LOADING_BENEFITS_AND_TSI:
      return {
        ...state,
        error: true,
        loading: false
      };
    case actionTypes.SAVING_PERSON:
      return {
        ...state,
        loading: true
      };
    case actionTypes.ERROR_SAVING_PERSON:
      return {
        ...state,
        error: true,
        loading: false
      };
    case actionTypes.LOADING_PERSONS_BY_PHONE_NUMBER:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOADED_PERSONS_BY_PHONE_NUMBER:
      return {
        ...state,
        persons: action.payload.response.data,
        loading: false
      };
    case actionTypes.ERROR_LOADING_PERSONS_BY_PHONE_NUMBER:
      return {
        ...state,
        error: true,
        loading: false
      };
    default:
      return state;
  }
}
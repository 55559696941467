import qs from 'query-string'
import cookie from 'js-cookie'

const utm = {
  /**
   * Create new UTM and store it to cookie if any
   */
  create: () => {
    // Get and create utm
    const query = qs.parse(window.location.search);

    // Set UTM Resource
    let utmResource = {};
    if (query.utm_source) utmResource.utm_source = query.utm_source;
    if (query.utm_medium) utmResource.utm_medium = query.utm_medium;
    if (query.utm_campaign) utmResource.utm_campaign = query.utm_campaign;
    if (query.utm_term) utmResource.utm_term = query.utm_term;
    if (query.utm_content) utmResource.utm_content = query.utm_content;

    if (!Object.keys(utmResource).length) {
      utm.clear();
      return;
    }

    // sets the expiry date as 14 days
    const exp = new Date(new Date().getTime() + 24 * 60 * 60 * 14);

    cookie.set('LIFEPAL_UTM', `${qs.stringify(utmResource)}`, {
      expires: exp,
      path: window.location.path,
      domain: window.location.host.replace(':3000', '')
    });
  },

  /**
   * Get all utm value
   */
  getAll: () => {
    // Check cookie first
    const lifepalUTM = cookie.get('LIFEPAL_UTM');

    if (!lifepalUTM) {
      utm.create();
      return qs.parse(cookie.get('LIFEPAL_UTM'));
    }

    return qs.parse(lifepalUTM);
  },

  /**
   * Clear UTM cookie
   */
  clear: () => {
    cookie.remove('LIFEPAL_AUTH');
  }
};

export default utm;

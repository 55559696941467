import React from 'react';

import { useScript } from '../client/utils/useScript';

export const UseScriptWrapper = ({ URLList }) => {
	if (URLList && URLList.length) {
		URLList.map(urlConfig => {
			useScript({
				url: urlConfig.url,
				id: urlConfig.id
			});
		});
	}

	return (
		<></>
	)
};

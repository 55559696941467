import { call, put } from "redux-saga/effects";
import getConfig from 'next/config';
import qs from 'query-string';
import get from 'lodash/get';

import { NextServerAPI } from '../../../utils/coreServerAPI';
import { getPolicyMembers } from '../../../../lib/createMember';
import actions, { actionTypes } from "../../../shell/actions";
import { toast } from "react-toastify";

const { MIDTRANS_SERVER_KEY } = getConfig().serverRuntimeConfig;
const { ORDER_TOKEN } = getConfig().publicRuntimeConfig;

export function* getOrder(action) {
  const { orderId, token } = action.payload;

  yield put(actions.loadingOrder());

  try {
    const response = yield call(NextServerAPI, {
      method: 'GET',
      AuthorizationToken: `bearer ${token}`,
      path: `/api/orders/${orderId}/`,
    });

    yield put(actions.loadedOrder({ response }));

    action.callback && action.callback(response);
  }
  catch (e) {
    console.error('error: ', e)
    yield put(actions.errorLoadingOrder({ errorObject: e }));
  }
}

export function* getOrderByQuotationId(action) {
  const { quotationId, token } = action.payload;

  yield put(actions.loadingOrderByQuotationId());

  try {
    const response = yield call(NextServerAPI, {
      method: 'GET',
      AuthorizationToken: `bearer ${token}`,
      path: `/api/orders/quotation/?quotationId=${quotationId}`,
    });

    action.callback && action.callback(response);
    yield put(actions.loadedOrderByQuotationId({ response }));
  } catch (e) {
    yield put(actions.loadedOrderByQuotationId({ response: { data: {} } }));
  }
}

export function* getOutstandingPayments(action) {
  const { humanId, token } = action.payload;

  yield put(actions.loadingOutstandingPayments());

  try {
    const response = yield call(NextServerAPI, {
      method: 'GET',
      AuthorizationToken: `bearer ${token}`,
      path: `/api/orders/outstanding-payments/?orderHumanId=${humanId}`,
    });

    yield put(actions.loadedOutstandingPayments({ response }));
  }
  catch (e) {
    console.error('error: ', e)
    yield put(actions.errorLoadingOutstandingPayments({ errorObject: e }));
  }
}

export function* payOutstandingTransactions(action) {
  const { data } = action.payload;

  yield put(actions.payingOutstandingTransactions());

  try {
    const response = yield call(NextServerAPI, {
      method: 'POST',
      AuthorizationToken: ORDER_TOKEN,
      path: `/api/orders/payments/outstanding-transactions/`,
      data,
    });

    action.callback && action.callback();

    if (response.data.status && response.data.errorMessage) {
      yield put(actions.errorPayingOutstandingTransactions({ errorMessage: response.data.errorMessage }));
    } else {
      yield put(actions.paidOutstandingTransactions({ response }));
    }
  } catch (error) {
    yield put(actions.errorPayingOutstandingTransactions({ errorMessage: 'Terjadi Kesalahan.' }));
  }
}

export function* initiatePayment(action) {
  yield put(actions.initiatingPayment());

  try {
    const response = yield call(NextServerAPI, {
      method: 'POST',
      path: `/api/orders/initiate-payment/`,
      data: action.payload,
    });

    if (response.data.status && response.data.errorMessage) {
      yield put(actions.errorInitiatingPayment({ errorMessage: response.data.errorMessage }));
      action.callback && action.callback({ response });
    } else {
      action.callback && action.callback({ response });
      yield put(actions.initiatedPayment({ response }));
    }
  } catch (error) {
    yield put(actions.errorInitiatingPayment({ errorMessage: 'Terjadi Kesalahan.' }));
    action.callback && action.callback({ data: { errorMessage: 'Terjadi Kesalahan.' } });
  }
}

export function* getTransactionStatus(action) {
  const { id } = action.payload;

  yield put(actions.loadingTransactionStatus());

  try {
    const response = yield call(NextServerAPI, {
      method: 'GET',
      path: `/api/orders/transaction-status/${id}/`,
      AuthorizationToken: Buffer.from(MIDTRANS_SERVER_KEY).toString('base64'),
    });

    if (response.data.status) {
      yield put(actions.errorLoadingTransactionStatus({ errorMessage: response.data.errorMessage.message }));
    } else {
      yield put(actions.loadedTransactionStatus({ response }));
    }
  } catch (error) {
    yield put(actions.errorLoadingTransactionStatus({ errorMessage: 'Terjadi Kesalahan.' }));
  }
}

export function* getCheckoutData(action) {
  const { token, query } = action.payload;

  yield put(actions.loadingCheckoutData());

  try {
    const quotation = yield call(NextServerAPI, {
      method: 'GET',
      AuthorizationToken: `bearer ${token}`,
      path: `/api/quotations/?${qs.stringify(query)}`,
    });

    const mainInsured = get(quotation, 'data.policyMembers', []).find(d => d.type === 'INSURED' && !d.relation);
    const holder = get(quotation, 'data.policyMembers', []).find(d => d.type === 'HOLDER');
    const buyer = get(quotation, 'data.buyer', {});

    const budget = get(quotation, 'data.declaration.product.budget', 0);
    const duration = get(quotation, 'data.declaration.duration', 0);
    const autoObject = get(quotation, 'data.policyMembers', []).find(row => row.insuredItem);
    const year = get(autoObject, 'insuredItem.year', null);
    const sumInsured = get(autoObject, 'insuredItem.sumInsured', null);
    const benefits = get(autoObject, 'insuredItem.additionalBenefits', null);
    const term = get(autoObject, 'insuredItem.term', null);

    let namePlan;
    if (autoObject) {
      const { brand, model, type, region } = autoObject.insuredItem;
      namePlan = `${brand}_${model}_${type}_${region}`;
    }

    const memberList = get(quotation, 'data.policyMembers', []).filter(row => (row.person && row.type === 'INSURED'));
    const members = memberList.length ? JSON.stringify(getPolicyMembers(memberList, duration)) : null;

    const planQueryObject = {
      planId: get(quotation, 'data.planId', null),
      ...year && { year },
      ...term && { term },
      ...members && { member: members },
      ...namePlan && { name: namePlan },
      ...benefits && { additional_benefits: JSON.stringify(benefits) },
      ...sumInsured && { sum_insured: Math.trunc(sumInsured) },
      ...budget && { budget },
    };

    const plan = yield call(NextServerAPI, {
      method: 'GET',
      path: `/api/products/plans/?${qs.stringify(planQueryObject)}`,
      AuthorizationToken: `bearer ${token}`,
    });

    action.callback(plan);

    yield put(actions.loadedCheckoutData({ quotation, plan, mainInsured, holder, buyer }));
  } catch (error) {
    console.error(error);
    yield put(actions.errorLoadingCheckoutData());
  }
}

export function* sendPolicy(action) {
  const { policyId, token } = action.payload;

  yield put(actions.sendingPolicy());

  try {
    const response = yield call(NextServerAPI, {
      method: 'PATCH',
      path: `/api/orders/send-policy/${policyId}/`,
      AuthorizationToken: `bearer ${token}`,
    });

    if (response.data.status) {
      toast.error(response.data.errorMessage.message);
      yield put(actions.errorSendingPolicy({ errorMessage: response.data.errorMessage.message }));
    } else {
      toast.info('Polis berhasil dikirim');
      yield put(actions.sentPolicy({ response }));
    }
  } catch (error) {
    toast.error('Terjadi Kesalahan');
    yield put(actions.errorSendingPolicy({ errorMessage: 'Terjadi Kesalahan.' }));
  }
}

export function* addPaymentLinkVoucher(action) {
  const { voucherCode, orderId } = action.payload;

  yield put(actions.addingPaymentLinkVoucher());

  try {
    const response = yield call(NextServerAPI, {
      method: 'PUT',
      path: `/api/orders/voucher/add/?voucherCode=${voucherCode}&orderId=${orderId}`,
    });

    action.callback && action.callback(response);

    if (response.data && response.data.errorMessage) {
      yield put(actions.errorAddingPaymentLinkVoucher());
    } else {
      yield put(actions.addedPaymentLinkVoucher({ response }));
    }
  } catch (error) {
    toast.error('Terjadi Kesalahan');
    yield put(actions.errorAddingPaymentLinkVoucher());
  }
}

export function* getLatestPayment(action) {
  const { token } = action.payload;

  yield put(actions.loadingLatestPayment());

  try {
    const response = yield call(NextServerAPI, {
      method: 'GET',
      path: `/api/orders/latest-payment/`,
      AuthorizationToken: `bearer ${token}`,
    });

    if (response.data.errorMessage || response.data.message) {
      yield put(actions.errorLoadingLatestPayment({ response: { data: {} } }));
    } else {
      yield put(actions.loadedLatestPayment({ response }));
    }
  } catch (error) {
    yield put(actions.errorLoadingLatestPayment({ errorMessage: 'Terjadi Kesalahan.' }));
  }
}

export function* duplicateOrder(action) {
  const { orderHumanId, token } = action.payload;

  yield put(actions.duplicatingOrder());

  try {
    const response = yield call(NextServerAPI, {
      method: 'POST',
      path: '/api/orders/duplicate/',
      AuthorizationToken: `bearer ${token}`,
      data: { orderHumanId },
    });

    if (response.data.errorMessage && response.data.errorMessage.message) {
      toast.error(response.data.errorMessage.message);
      yield put(actions.errorDuplicatingOrder());
    } else {
      if (action.callback) {
        action.callback(response);
      }
      yield put(actions.duplicatedOrder({ response }));
    }
  } catch (error) {
    const errorMessage = 'Terjadi Kesalahan.';
    toast.error(errorMessage);

    yield put(actions.errorDuplicatingOrder({ errorMessage }));
  }
}

export function* getQuotationSlip(action) {
  const { orderHumanId, token } = action.payload;

  yield put(actions.loadingQuotationSlip());

  try {
    const response = yield call(NextServerAPI, {
      method: 'GET',
      path: `/api/orders/${orderHumanId}/quotationSlip/`,
      AuthorizationToken: `bearer ${token}`,
    });

    if (response.data) {
      action.callback(response.data);
    }
  } catch (error) {
    yield put(actions.errorLoadingQuotationSlip());
  }
}

export default [
  [actionTypes.GET_ORDER, getOrder],
  [actionTypes.GET_OUTSTANDING_PAYMENTS, getOutstandingPayments],
  [actionTypes.PAY_OUTSTANDING_TRANSACTIONS, payOutstandingTransactions],
  [actionTypes.INITIATE_PAYMENT, initiatePayment],
  [actionTypes.GET_TRANSACTION_STATUS, getTransactionStatus],
  [actionTypes.GET_ORDER_BY_QUOTATION_ID, getOrderByQuotationId],
  [actionTypes.GET_CHECKOUT_DATA, getCheckoutData],
  [actionTypes.SEND_POLICY, sendPolicy],
  [actionTypes.ADD_PAYMENT_LINK_VOUCHER, addPaymentLinkVoucher],
  [actionTypes.GET_LATEST_PAYMENT, getLatestPayment],
  [actionTypes.DUPLICATE_ORDER, duplicateOrder],
  [actionTypes.GET_QUOTATION_SLIP, getQuotationSlip],
];

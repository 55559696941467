import React from 'react';
import { Footer as LpFooter } from '@lifepal/lifepal-design-system';
import './Footer.module.scss';

export const Footer = (props) => {
  return (
    <LpFooter
      footerImage={"/static/images/logo/payment_partners.png"}
      {...props}
    />
  );
}
import { call, put } from "redux-saga/effects";
import qs from 'query-string';

import actions, { actionTypes } from "../../../shell/actions";
import { NextServerAPI } from '../../../utils';


export function* getAutoBrands(action) {
  const { category } = action.payload;

  yield put(actions.loadingAutoBrands({ loading: true }))

  try {
    const response = yield call(NextServerAPI, {
      method: 'GET',
      path: `/api/products/auto/brands/?category=${category}`,
    });

    yield put(actions.loadedAutoBrands({ response }))
  }
  catch (e) {
    console.error(e);
  }
}

export function* getAutoModels(action) {
  const { category, brandId } = action.payload;

  yield put(actions.loadingAutoModels({ loading: true }))

  try {
    const response = yield call(NextServerAPI, {
      method: 'GET',
      path: `/api/products/auto/models/?${qs.stringify({ category, brandId })}`
    });

    yield put(actions.loadedAutoModels({ response }))
  }
  catch (e) {
    console.error(e);
  }
}

export function* getAutoTypes(action) {
  const { modelId } = action.payload;

  yield put(actions.loadingAutoTypes({ loading: true }))

  try {
    const response = yield call(NextServerAPI, {
      method: 'GET',
      path: `/api/products/auto/types/?${qs.stringify({ modelId })}`
    });

    yield put(actions.loadedAutoTypes({ response }))
  }
  catch (e) {
    console.error(e);
  }
}

export function* getAutoYears(action) {
  const { typeId } = action.payload;

  yield put(actions.loadingAutoYears({ loading: true }))

  try {
    const response = yield call(NextServerAPI, {
      method: 'GET',
      path: `/api/products/auto/years/?${qs.stringify({ typeId })}`
    });

    yield put(actions.loadedAutoYears({ response }))
  }
  catch (e) {
    console.error(e);
  }
}

export function* getAutoRangePrice(action) {
  const { namePlan, vehicleYear } = action.payload;

  const [brand, model, type] = namePlan.split('_');

  yield put(actions.loadingAutoRangePrice());

  try {
    const response = yield call(NextServerAPI, {
      method: 'GET',
      path: `/api/products/auto/range-price/?year=${vehicleYear}&brand_name=${encodeURIComponent(brand)}&model_name=${encodeURIComponent(model)}&type_name=${encodeURIComponent(type)}`,
    });

    yield put(actions.loadedAutoRangePrice({ response }))
  }
  catch (e) {
    yield put(actions.errorLoadingAutoRangePrice()); 
  }
}

export default [
  [actionTypes.GET_AUTO_BRANDS, getAutoBrands],
  [actionTypes.GET_AUTO_MODELS, getAutoModels],
  [actionTypes.GET_AUTO_TYPES, getAutoTypes],
  [actionTypes.GET_AUTO_YEARS, getAutoYears],
  [actionTypes.GET_AUTO_RANGE_PRICE, getAutoRangePrice],
];

import { createStore, applyMiddleware, compose } from 'redux';
import { takeLatest, all, fork } from 'redux-saga/effects';
import createSagaMiddleware from 'redux-saga';
import { persistStore } from 'redux-persist';

import { rootReducer } from './reducers';
import allSagas from './sagas';

function createWatcher(actionSagaPair) {
  return function* () {
    yield takeLatest(...actionSagaPair);
  };
}

function returnFork(actionFunction) {
  return fork(actionFunction);
}
/**
* @param {object} initialState
* @param {boolean} options.isServer indicates whether it is a server side or client side
* @param {Request} options.req NodeJS Request object (not set when client applies initialState from server)
* @param {Request} options.res NodeJS Request object (not set when client applies initialState from server)
* @param {boolean} options.debug User-defined debug mode param
* @param {string} options.storeKey This key will be used to preserve store in global namespace for safe HMR
*/
const makeStore = (initialState) => {
  let store;
  const sagaMiddleware = createSagaMiddleware(),
    composeEnhancer = (typeof window !== 'undefined' &&
      (process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : '')) || compose,
    createStoreRedux = composeEnhancer(applyMiddleware(sagaMiddleware))(createStore);

  const isClient = typeof window !== 'undefined';

  if (isClient) {
    store = createStoreRedux(rootReducer, initialState);
    store.__PERSISTOR = persistStore(store);
  }
  else {
    store = createStoreRedux(rootReducer, initialState);
  }

  const sagaWithWatcher = allSagas.map(createWatcher);

  function* rootSaga() {
    yield all(sagaWithWatcher.map(returnFork));
  }

  store.sagaTask = sagaMiddleware.run(rootSaga);

  return store;
};

export default makeStore;

import React, { useState, useEffect, useContext, createContext } from 'react';

import jsCookie from 'js-cookie'

import decode from 'jsonwebtoken/decode';

import { validateAgentRole, userActivity } from 'clientUtils';
import { AGENT_CONSTANTS } from '../client/modules/agents/constants/agent.constants';

const AuthContext = createContext({
  isAuthenticated: false,
  setAuthenticated: () => {},
  isLoading: true,
  authToken: '',
  me: {},
  isAgent: false,
  setAgent: () => {},
  isOfflineLeader: false,
  setMe: () => {},
  setAuthToken: () => {},
  isTSOSupervisor: false,
  setTSOSupervisor: () => {},
  isRegistered: false, // only for offline-agents who are registering
  setRegistered: () => {},
});

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [isAgent, setAgent] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [authToken, setAuthToken] = useState('');
  const [me, setMe] = useState({});
  const [isTSOSupervisor, setTSOSupervisor] = useState(false);
  const [isRegistered, setRegistered] = useState(false);

  const initializeAuth = async () => {
    const response = await (await fetch('/api/auth/')).json();
    const token = jsCookie.get('token');
    const decodedMe = decode(token);
    setAuthenticated(response.isAuthenticated);
    setAuthToken(token);
    setMe(decodedMe);
    if (decodedMe && validateAgentRole(decodedMe.groups)) {
      setAgent(true);
      if (AGENT_CONSTANTS.getAgentByToken(token).isTSOSupervisor) {
        setTSOSupervisor(true);
      }

      userActivity.init(token);
    }
    setLoading(false);
  };

  useEffect(() => {
    initializeAuth();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isLoading,
        setAuthenticated,
        authToken,
        me,
        isAgent,
        setAgent,
        setMe,
        setAuthToken,
        isTSOSupervisor,
        setTSOSupervisor,
        isRegistered,
        setRegistered
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const useIsAuthenticated = () => {
  const context = useAuth();
  return context.isAuthenticated;
};

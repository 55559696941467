import { gql, useQuery, useLazyQuery } from '@apollo/client';

/**
 * Used as cursor
 * SET IN: /lib/apolloClient.js
 * READ MORE: https://www.apollographql.com/docs/react/pagination/cursor-based/
 **/

const NEARBY_INSURANCE_PROVIDER = gql`
  query getNearbyInsuranceProviders(
    $vehicleBrandName: String,
    $category: String,
    $brandId: Int,
    $latitude: Float!,
    $longitude: Float!,
    $radiusMeters: Float!,
    $pageSize: Int,
    $cursor: String,
  ){
    getNearbyInsuranceProviders(input: {
      brandId: $brandId,
      vehicleBrandName: $vehicleBrandName,
      category: $category,
      location: {
        latitude: $latitude,
        longitude: $longitude,
        radiusMeters: $radiusMeters,
      },
      cursor: $cursor,
      pageSize: $pageSize,
    }) {
      data{
        id
        longitude
        latitude
        name
        address
        longitude
        latitude
        distanceMeters
        category
      },
      nextCursor
    }
  }
`;

export const getNearbyInsuranceProvider = (props) => {
  return useQuery(NEARBY_INSURANCE_PROVIDER, {
    variables: props
  });
};

export const lazyGetNearbyInsuranceProvider = () => {
  return useLazyQuery(NEARBY_INSURANCE_PROVIDER);
}

export const nearbyInsurancePolicy = {
  getNearbyInsuranceProviders: {
    keyArgs: false,
    merge(existing, incoming) {
      return {
        ...incoming,
        data: existing ? existing.data.concat(incoming?.data) :incoming.data,
      };
    },
  },
}

const QUERY_INSURANCE_PROVIDER = gql`
  query getInsuranceProvidersByProduct($productIds: [Int!]){
    getInsuranceProvidersByProduct( input: {
      productIds: $productIds
    }){
      data {
        id,
        name,
        address,
      }
    }
}`

export const getInsuranceProvider = (productId) => {
  return useQuery(QUERY_INSURANCE_PROVIDER, {
    variables: {
      productIds: [productId]
    }
  })
};
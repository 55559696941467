/* eslint-disable no-nested-ternary */
/* eslint-disable eqeqeq */
import Router from 'next/router';
import { call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import qs from 'query-string';
import daysjs from 'dayjs';
import get from 'lodash/get';
import jsCookie from "js-cookie";
import actions, { actionTypes } from '../../../shell/actions';
import { NextServerAPI, tomiAI } from '../../../utils';
import { getPolicyMembers } from '../../../../lib/createMember';
import { AGENT_CONSTANTS } from 'client/modules/agents/constants/agent.constants';

function getFilterForList(type, searchQuery) {
  let filterObject = {};

  switch (type) {
    case 'INCOMPLETE':
      filterObject = {
        statuses: ['ACTIVE', 'PENDING_QA', 'REVISE'],
        stages: ['CREATE_QUOTATION', 'POLICY_INFO', 'DECLARATION', 'BUYER_INFO'],
        mainInsuredNameOrEmail: searchQuery,
      };
      break;
    case 'SUSPENDED':
      filterObject = {
        statuses: ['SUSPEND'],
        stages: ['CREATE_ORDER'],
      };
      break;
    case 'IN_PROCESS':
      filterObject = { statuses: ['PAYMENT_PENDING', 'PAYMENT_PROCESSING'] };
      break;
    case 'AWAITING_ISSUANCE':
      filterObject = {
        statuses: ['FULLY_PAID', 'PARTIALLY_PAID'],
        published: 0,
        readyToIssue: 0,
      };
      break;
    case 'DASHBOARD':
      filterObject = { stages: ['CREATE_ORDER'] };
      break;
    default:
      filterObject = {};
  }

  if (searchQuery && type != 'INCOMPLETE') {
    filterObject = {
      ...filterObject,
      ...searchQuery.includes('LP-')
        ? { orderHumanId: searchQuery }
        : type != 'DASHBOARD'
          ? { buyerNameOrEmail: searchQuery }
          : { buyerNameOrEmailOrPhone: searchQuery },
    };
  }
  return JSON.stringify(filterObject);
}

function isValidSession(response) {
  if (response.data && response.data.status === 401) {
    toast.error(response.data.errorMessage);
    window.handleLogout();
  }
};

export function* updateQuotation(action) {
  const { quotationDetail, token } = action.payload;

  yield put(actions.updatingQuotation({ loading: true }));

  try {
    const response = yield call(NextServerAPI, {
      method: 'POST',
      AuthorizationToken: `bearer ${token}`,
      path: '/api/quotations/',
      data: quotationDetail,
    });

    yield put(actions.updatedQuotation({ response }));
  } catch (e) {
    console.log('Error: ', e);
    yield put(actions.errorUpdatingQuotation({ errorObject: e }));
  }
}

export function* getAgentMonthlyData(action) {
  const { queryObject, token } = action.payload;

  yield put(actions.loadingAgentMonthlyData({ loading: true }));

  try {
    const monthlyData = yield call(NextServerAPI, {
      method: 'GET',
      AuthorizationToken: `bearer ${token}`,
      path: `/api/agents/overview/data/?${qs.stringify(queryObject)}`,
    });

    action.callback && action.callback(monthlyData);

    yield put(actions.loadedAgentMonthlyData({ monthlyData }));
  } catch (e) {
    console.log('Error: ', e);
    yield put(actions.errorLoadingAgentMonthlyData({ errorObject: e }));
  }
}

export function* getAgentLevel(action) {
  const { token } = action.payload;

  yield put(actions.loadingAgentLevel());

  try {
    const agentLevel = yield call(NextServerAPI, {
      method: 'GET',
      AuthorizationToken: `Bearer ${token}`,
      path: '/api/agents/level/',
    });

    if (agentLevel.data.statusCode) {
      yield put(actions.errorLoadingAgentLevel({ errorMesage: agentLevel.data.message }));
    } else {
      yield put(actions.loadedAgentLevel({ agentLevel }));
    }
  } catch (e) {
    yield put(actions.errorLoadingAgentLevel({ errorMesage: 'Terjadi Kesalahan' }));
  }
}

export function* agentDashboardSearch(action) {
  const { queryObject, token } = action.payload;

  yield put(actions.searchingAgentDashboard({ loading: true }));

  try {
    const monthlyData = yield call(NextServerAPI, {
      method: 'GET',
      AuthorizationToken: `bearer ${token}`,
      path: `/api/agents/overview/data/?${qs.stringify({
        filter: getFilterForList('DASHBOARD', queryObject.searchQuery),
        page: queryObject.page,
      })}`,
    });

    yield put(actions.agentDashboardSearchResult({ monthlyData }));
  } catch (e) {
    console.log('Error: ', e);
    // yield put(actions.errorLoadingAgentMonthlyData({ errorObject: e }));
  }
}

export function* updateSchedule(action) {
  const { token, schedule } = action.payload;

  yield put(actions.updatingSchedule());

  try {
    const response = yield call(NextServerAPI, {
      method: 'PUT',
      AuthorizationToken: `bearer ${token}`,
      path: '/api/orders/schedule/callback/',
      data: schedule,
    });

    yield put(actions.updatedSchedule(response));
  } catch (e) {
    console.log('Error: ', e);
  }
}

export function* getAgentQuotationPlanAndOrder(action) {
  const { quotationId, phoneNumber, token } = action.payload;

  yield put(actions.loadingAgentQuotationPlanAndOrder());

  try {
    const quotation = yield call(NextServerAPI, {
      method: 'GET',
      AuthorizationToken: `bearer ${token}`,
      path: `/api/quotations/?quotationId=${quotationId}&buyerPhone=${phoneNumber}`,
    });

    const budget = get(quotation, 'data.declaration.product.budget', 0);
    const duration = get(quotation, 'data.declaration.duration', 0);
    const autoObject = get(quotation, 'data.policyMembers', []).find(row => row.insuredItem);
    const year = get(autoObject, 'insuredItem.year', null);
    const sumInsured = get(autoObject, 'insuredItem.sumInsured', null);
    const benefits = get(autoObject, 'insuredItem.additionalBenefits', null);
    const term = get(autoObject, 'insuredItem.term', null);

    let namePlan;
    if (autoObject) {
      const { brand, model, type, region } = autoObject.insuredItem;
      namePlan = `${brand}_${model}_${type}_${region}`;
    }

    const memberList = get(quotation, 'data.policyMembers', []).filter(row => (row.person && row.type === 'INSURED'));
    const members = memberList.length ? JSON.stringify(getPolicyMembers(memberList, duration)) : null;

    const planQueryObject = {
      planId: get(quotation, 'data.planId', null),
      ...year && { year },
      ...term && { term },
      ...members && { member: members },
      ...namePlan && { name: namePlan },
      ...benefits && { additional_benefits: JSON.stringify(benefits) },
      ...sumInsured && { sum_insured: sumInsured },
      ...budget && { budget },
    };

    const plan = yield call(NextServerAPI, {
      method: 'GET',
      path: `/api/products/plans/?${qs.stringify(planQueryObject)}`,
      AuthorizationToken: `bearer ${token}`,
    });

    const declaration = yield call(NextServerAPI, {
      method: 'GET',
      path: `/api/products/${plan.data.product.id}/declarations/`,
    });

    const order = yield call(NextServerAPI, {
      method: 'GET',
      AuthorizationToken: `bearer ${token}`,
      path: `/api/orders/quotation/?quotationId=${quotationId}`,
    });

    yield put(actions.loadedAgentQuotationPlanAndOrder({ quotation, plan, declaration, order }));
    action.callback && action.callback(quotation.data);
  } catch (e) {
    console.log('Error: ', e);
    yield put(actions.errorLoadingAgentQuotationPlanAndOrder());
  }
}

export function* getPerson(action) {
  const { token, userId } = action.payload;

  yield put(actions.loadingPerson());

  try {
    const person = yield call(NextServerAPI, {
      method: 'GET',
      AuthorizationToken: `bearer ${token}`,
      path: `/api/quotations/person/${userId}/`,
    });

    action.callback && action.callback({success: true, person});

    yield put(actions.loadedPerson({ person }));
  } catch (e) {
    console.log(e);
    action.callback && action.callback({success: false});
    yield put(actions.errorLoadingPerson());
  }
}

export function* updatePerson(action) {
  const { token, person, method } = action.payload;

  yield put(actions.updatingPerson());

  const status = {
    isSuccess: false,
    errorMessage: '',
  };

  try {
    yield call(NextServerAPI, {
      method,
      AuthorizationToken: `bearer ${token}`,
      path: '/api/quotations/persons/',
      data: person,
    });

    status.isSuccess = true;

    yield put(actions.updatedPerson());
    yield put(actions.setUpdatePersonStatus());
  } catch (e) {
    let errorMessage = 'Mohon coba beberapa saat lagi.';

    if (e.code === 400) {
      errorMessage = 'Input tidak sesuai.';
    }

    if (person.isUpdateUser) {
      errorMessage = 'Email atau nomor telepon sudah terdaftar.';
    }
    status.isSuccess = false;
    status.errorMessage = errorMessage;
    yield put(actions.errorUpdatingPerson({ errorMessage }));
  }

  if (action.callback) {
    action.callback(status);
  }
}

export function* updateQuotations(action) {
  const { token, data, method } = action.payload;

  yield put(actions.updatingQuotations());

  try {
    yield call(NextServerAPI, {
      method,
      AuthorizationToken: `bearer ${token}`,
      path: '/api/quotations/',
      data,
    })

    action.callback && action.callback();
    yield put(actions.updatedQuotations());
    yield put(actions.setQuotationsStatus());
  } catch (error) {
    yield put(actions.errorUpdatingPerson());
  }
}

export function* getPersonalAccount(action) {
  const { token, id } = action.payload;

  yield put(actions.loadingPersonalAccount());

  try {
    const personalAccount = yield call(NextServerAPI, {
      method: 'GET',
      AuthorizationToken: `bearer ${token}`,
      path: `/api/quotations/personal-account/?id=${id}`,
    });

    yield put(actions.loadedPersonalAccount({ personalAccount }));
  } catch (e) {
    let errorMessage = 'Mohon coba beberapa saat lagi.';

    if (e.code === 400) {
      errorMessage = 'Input tidak sesuai.';
    }

    yield put(actions.errorLoadingPersonalAccount({ errorMessage }));
  }
}

export function* updatePolicyMember(action) {
  const { token, member } = action.payload;

  yield put(actions.updatingPolicyMember());

  try {
    yield call(NextServerAPI, {
      method: 'PUT',
      AuthorizationToken: `bearer ${token}`,
      path: '/api/quotations/policy-members/',
      data: member,
    });

    action.callback && action.callback();
    yield put(actions.updatedPolicyMember());
    yield put(actions.setUpdatePolicyMemberStatus());
  } catch (e) {
    let errorMessage = 'Mohon coba beberapa saat lagi.';

    if (e.code === 400) {
      errorMessage = 'Input tidak sesuai.';
    }

    yield put(actions.errorUpdatingPolicyMember({ errorMessage }));
  }
}

export function* updateDeclaration(action) {
  const { token, declaration, quotationId, buyerPhone } = action.payload;

  yield put(actions.updatingDeclaration());

  try {
    yield call(NextServerAPI, {
      method: 'PATCH',
      AuthorizationToken: `bearer ${token}`,
      path: `/api/quotations/${quotationId}/declaration/?buyerPhone=${buyerPhone}`,
      data: declaration,
    });

    yield put(actions.updatedDeclaration());
    yield put(actions.setUpdateDeclarationStatus());
  } catch (e) {
    let errorMessage = 'Mohon coba beberapa saat lagi.';

    if (e.code === 400) {
      errorMessage = 'Input tidak sesuai.';
    }

    yield put(actions.errorUpdatingDeclaration({ errorMessage }));
  }

  action.callback && action.callback();
}

export function* updateQuotationStatus(action) {
  const { token, status, quotationId, buyerPhone } = action.payload;

  yield put(actions.updatingQuotationStatus());

  try {
    yield call(NextServerAPI, {
      method: 'PATCH',
      AuthorizationToken: `bearer ${token}`,
      path: `/api/quotations/${quotationId}/status/?buyerPhone=${buyerPhone}`,
      data: status,
    });

    yield put(actions.updatedQuotationStatus());
  } catch (e) {
    let errorMessage = 'Mohon coba beberapa saat lagi.';

    if (e.code === 400) {
      errorMessage = 'Input tidak sesuai.';
    }

    yield put(actions.errorUpdatingQuotationStatus({ errorMessage }));
  }
}

export function* updateRefundOrder(action) {
  const { token, payload } = action.payload;

  yield put(actions.updatingRefundOrder());

  try {
    yield call(NextServerAPI, {
      method: 'POST',
      AuthorizationToken: `bearer ${token}`,
      path: '/api/orders/payments/refund/',
      data: payload,
    });

    yield put(actions.updatedRefundOrder());
  } catch (e) {
    let errorMessage = 'Mohon coba beberapa saat lagi.';

    if (e.code === 404) {
      errorMessage = 'Order sudah di Refund.';
    }

    yield put(actions.errorUpdatingRefundOrder({ errorMessage }));
  }
}

export function* getLeadInfo(action) {
  const { leadMobileNumber, token } = action.payload;

  yield put(actions.loadingLeadInfo({ loading: true }));

  try {
    const response = yield call(NextServerAPI, {
      AuthorizationToken: `Bearer ${token}`,
      method: 'GET',
      path: `/api/track/leads/?phoneNumber=${leadMobileNumber}`,
    });

    // next.js server side handled errorMessage check from our backend
    if (response.data.errorMessage) {
      // here we have to notify the error to the user and toggling of the loader
      // yield put(actions.errorLoadingLeadInfo({ response }));
      // toast.error(response.data.errorMessage);
      yield put(actions.errorLoadingLeadInfo({ errorMessage: 'Error loading lead info' }));
    }

    // this has to be handled in the else case
    yield put(actions.loadedLeadInfo({ response }));
  } catch (e) {
    // this block is for handling erroes which is not handled in the next.js server-side
    // toast.warn('Something went wrong');
    yield put(actions.errorLoadingLeadInfo({ errorMessage: 'Error loading lead info' }));
  }
}

export function* getLeadQuotationInfo(action) {
  const { leadMobileNumber, token } = action.payload;

  yield put(actions.loadingLeadQuotationInfo({ loading: true }));

  try {
    const response = yield call(NextServerAPI, {
      AuthorizationToken: `Bearer ${token}`,
      method: 'GET',
      path: `/api/leads/quotations/${leadMobileNumber}/`,
    });

    yield put(actions.loadedLeadQuotationInfo({ response }));
  } catch (e) {
    yield put(actions.errorLoadingLeadQuotationInfo({ errorMessage: 'Error loading lead quotation info' }));
  }
}

export function* createLead(action) {
  const { token, data, isABTest = false } = action.payload;

  yield put(actions.creatingLead());

  try {
    const response = yield call(NextServerAPI, {
      AuthorizationToken: `Bearer ${token}`,
      method: 'POST',
      path: '/api/leads/create-lead/',
      data,
    });

    if (response.data.statusCode === 400) {
      yield put(actions.errorCreatingLead({
        errorMessage: 'Maaf, Pelanggan sudah didaftarkan oleh agent Lifepal lainnya'
      }));
      toast.error('Maaf, Pelanggan sudah didaftarkan oleh agent Lifepal lainnya');
    } else if (response.data.status === 422) {
      yield put(actions.errorCreatingLead({
        errorMessage: 'Lead yang Anda tambahkan sudah ada di Lifepal'
      }));
      !isABTest && toast.error('Lead yang Anda tambahkan sudah ada di Lifepal');
    } else {
      yield put(actions.createdLead({ response }));
      action.callback && action.callback(response);
      !isABTest && toast.success('Leads Berhasil Ditambah');
    }
  } catch (e) {
    console.log(e);
  }
}

export function* getLeadList(action) {
  const { token } = action.payload;

  yield put(actions.loadingLeadList({ loading: true }));

  try {
    const response = yield call(NextServerAPI, {
      AuthorizationToken: `Bearer ${token}`,
      method: 'GET',
      path: '/api/leads/list/',
    });

    isValidSession(response);

    yield put(actions.loadedLeadList({ response }));
    action.callback && action.callback(true);
  } catch (e) {
    console.log(e);
  }
}

export function* updateQueueSchedule(action) {
  const { leadId, token, data } = action.payload;

  yield put(actions.updatingQueueSchedule());

  try {
    const response = yield call(NextServerAPI, {
      method: action.payload.method || 'PATCH',
      AuthorizationToken: `Bearer ${token}`,
      path: `/api/leads/schedule/${leadId}/`,
      data,
    });

    toast.success('Pelanggan telah dijadwal ulang untuk dihubungi kembali');

    action.callback && action.callback();

    if (!action.payload.method) {
      yield call(getLeadByLeadID, { payload: { token, leadId } });
    }

    yield put(actions.updatedQueueSchedule({ response, shouldSplice: action.payload.shouldSplice }));
  } catch (e) {
    const errorMessage = 'Terjadi kesalahan, silahkan coba berberapa saat lagi.';
    toast.error(errorMessage);
    action.callback && action.callback();
    yield put(actions.errorUpdatingQueueSchedule());
  }
}

export function* rejectLead(action) {
  const { queryId, token, data, isUpdatingNote } = action.payload;

  yield put(actions.rejectingLead());

  try {
    yield call(NextServerAPI, {
      method: 'PUT',
      AuthorizationToken: `Bearer ${token}`,
      path: `/api/leads/reject/${queryId}/`,
      data,
    });

    yield call(getLeadByLeadID, { payload: { token, leadId: queryId } });

    if (isUpdatingNote) {
      toast.success('Catatan berhasil ditambahkan');
    } else {
      toast.success('Pelanggan telah dihapus');
    }

    yield put(actions.rejectedLead());
  } catch (e) {
    const errorMessage = 'Terjadi kesalahan, silahkan coba berberapa saat lagi.';
    toast.error(errorMessage);

    yield put(actions.errorRejectingLead({ errorMessage }));
  }
}

export function* getBankAccountDetail(action) {
  const { token } = action.payload;
  yield put(actions.loadingBankAccountDetail());

  try {
    const response = yield call(NextServerAPI, {
      AuthorizationToken: `bearer ${token}`,
      method: 'GET',
      path: `/api/quotations/user/profile`,
    });
    yield put(actions.loadedBankAccountDetail({ response }));
  } catch (e) {
    const errorMessage = `Error fetching user bank account details`;
    yield put(actions.errorLoadingBankAccountDetail({ errorMessage }));
  }
}

export function* updatePersonalAccount(action) {
  const { token, account } = action.payload;

  yield put(actions.updatingPersonalAccount());

  try {
    yield call(NextServerAPI, {
      method: 'PUT',
      AuthorizationToken: `bearer ${token}`,
      path: '/api/quotations/personal-account/',
      data: account,
    });

    yield put(actions.updatedPersonalAccount());

    yield call(getBankAccountDetail, { payload: { token } });
    toast.success('Akun bank telah berhasil ditambah');
  } catch (e) {
    let errorMessage = 'Mohon coba beberapa saat lagi.';

    if (e.code === 400) {
      errorMessage = 'Input tidak sesuai.';
    }
    toast.error('Penambahan akun bank gagal. Silahkan coba lagi');
    yield put(actions.errorUpdatingPersonalAccount({ errorMessage }));
  }
}

export function* getBalance(action) {
  const { token } = action.payload;
  yield put(actions.loadingBalance());

  try {
    const response = yield call(NextServerAPI, {
      AuthorizationToken: `bearer ${token}`,
      method: 'GET',
      path: '/api/agents/balance-inquiry/get-balance/',
    });
    yield put(actions.loadedBalance({ response }));
  } catch (e) {
    const errorMessage = `Error Fetching Balance`;
    yield put(actions.errorLoadingBalance({ errorMessage }));
  }
}

export function* authenticateWithdrawal(action) {
  const { token, data } = action.payload;
  try {
    const response = yield call(NextServerAPI, {
      AuthorizationToken: `bearer ${token}`,
      method: 'POST',
      path: `/api/agents/balance-inquiry/authenticate-withdrawal/`,
      data: {
        'amount': data.amount,
      },
    });
    yield put(actions.authenticatedWithdrawal({ response }));
  } catch (e) {
    const errorMessage = 'Error authenticating withdrawal';
    yield put(actions.errorAuthenticatingWithdrawal({ errorMessage }));
    action.callback && action.callback();
  }
}

export function* processWithdrawal(action) {
  const { token, data } = action.payload;

  try {
    const response = yield call(NextServerAPI, {
      method: 'POST',
      AuthorizationToken: `bearer ${token}`,
      path: `/api/agents/balance-inquiry/withdrawal/`,
      data,
    });
    yield put(actions.processedWithdrawal({ response }));
    yield call(getBalance, { payload: { token } });
    action.callback && action.callback();
    toast.success('Penarikan Saldo Berhasil');
  } catch (e) {
    const errorMessage = 'Error Processing Withdrawal';
    toast.error(errorMessage);
    action.callback && action.callback();
    yield put(actions.errorProcessingWithdrawal({ errorMessage }));
  }
}

export function* getLeadSummaryList(action) {
  const { token, query } = action.payload;
  yield put(actions.loadingLeadSummaryList({ loading: true }));

  try {
    const response = yield call(NextServerAPI, {
      AuthorizationToken: `Bearer ${token}`,
      method: 'GET',
      path: `/api/agents/${query.page}/${query.search}/`,
    });

    yield put(actions.loadedLeadSummaryList({ response }));
  } catch (e) {
    const errorMessage = 'Error Loading Lead Summary List';
    toast.error(errorMessage);
  }
}

export function* getTransactionSummary(action) {
  const { token } = action.payload;

  const leadOwner = AGENT_CONSTANTS.getAgentByToken(token).leadOwner;
  let startDate;
  if (leadOwner === 'organization') {
    startDate = daysjs().startOf('month');
  }

  yield put(actions.loadingTransactionSummary());

  try {
    const response = yield call(NextServerAPI, {
      AuthorizationToken: `Bearer ${token}`,
      method: 'GET',
      path: `/api/leads/transaction-summary/?startDate${startDate}`,
    });

    yield put(actions.loadedTransactionSummary({ response }));
  } catch (e) {
    console.log(e);
    yield put(actions.errorLoadingTransactionSummary());
  }
}

export function* getAgentPerformance(action) {
  const { token, me } = action.payload;

  const leadOwner = AGENT_CONSTANTS.getAgentByToken(token).leadOwner;
  const query = {
    agentId: me.user_id,
    ...leadOwner === 'organization' ? {
      startDate: daysjs().startOf('month').format('YYYY-MM-DD'),
      endDate: daysjs().endOf('month').format('YYYY-MM-DD'),
    } : {},
  };

  yield put(actions.loadingAgentPerformance());

  try {
    const response = yield call(NextServerAPI, {
      method: 'GET',
      AuthorizationToken: '',
      path: `/api/orders/agent-performance/?${qs.stringify(query)}`,
    });

    yield put(actions.loadedAgentPerformance({ response }));
  } catch (e) {
    console.log(e);
    yield put(actions.errorLoadingAgentPerformance());
  }
}

export function* getAgentDetail(action) {
  const { token, agentId } = action.payload;

  yield put(actions.loadingAgentDetail());

  try {
    const response = yield call(NextServerAPI, {
      method: 'GET',
      AuthorizationToken: `Bearer ${token}`,
      path: `/api/agents/detail/${agentId}/`,
    });

    yield put(actions.loadedAgentDetail({ response }));
  } catch (e) {
    console.log(e);
    yield put(actions.errorLoadingAgentDetail());
  }
}

export function* getWithdrawalSummaryList(action) {
  const { token, query } = action.payload;
  yield put(actions.loadingWithdrawalSummaryList({ loading: true }));

  try {
    const response = yield call(NextServerAPI, {
      AuthorizationToken: `bearer ${token}`,
      method: 'GET',
      path: `/api/agents/balance-inquiry/withdrawal-history/?${qs.stringify(query)}`,
    });

    if (action.callback) {
      action.callback(response.data);
    }

    yield put(actions.loadedWithdrawalSummaryList({ response }));
  } catch (e) {
    const errorMessage = 'Error Loading Withdrawal Summary List';
    toast.error(errorMessage);
  }
}

export function* getUserDetails(action) {
  const { data, token } = action.payload;

  yield put(actions.loadingUserDetails());

  try {
    const response = yield call(NextServerAPI, {
      method: 'POST',
      AuthorizationToken: `Bearer ${token}`,
      path: '/api/user/user-by-id/',
      data
    });

    yield put(actions.loadedUserDetails({ response }));
  } catch (e) {
    yield put(actions.errorLoadingAgentData({ errorMesage: 'Error Loading User Details' }));
  }
}

export function* getAgentData(action) {
  const { token } = action.payload;

  yield put(actions.loadingAgentData());

  try {
    const agentData = yield call(NextServerAPI, {
      method: 'GET',
      AuthorizationToken: `Bearer ${token}`,
      path: '/api/agents/agent-data/',
    });

    isValidSession(agentData);

    if (agentData.data.statusCode) {
      yield put(actions.errorLoadingAgentData({ errorMesage: agentData.data.message }));
    } else {
      yield put(actions.loadedAgentData({ agentData }));
      action.callback && action.callback(agentData.data);
    }

  } catch (e) {
    yield put(actions.errorLoadingAgentData({ errorMesage: 'Error Loading Agent Data' }));
  }
}

export function* getAgentTeams(action) {
  const { token, agentId } = action.payload;

  yield put(actions.loadingAgentTeams());

  try {
    const agentTeams = yield call(NextServerAPI, {
      method: 'GET',
      AuthorizationToken: `Bearer ${token}`,
      path: `/api/agents/agent-teams/?agentId=${agentId}`,
    });

    if (agentTeams.data.statusCode) {
      yield put(actions.errorLoadingAgentTeams({ errorMesage: agentTeams.data.message }));
    } else {
      yield put(actions.loadedAgentTeams({ agentTeams }));
    }
  } catch (e) {
    yield put(actions.errorLoadingAgentTeams({ errorMesage: 'Error Loading Agent Teams' }));
  }
}

export function* createQueueEntry(action) {
  const { data } = action.payload;
  const gaId = tomiAI.getGaId();
  const landing_page = jsCookie.get('landing_page');
  const payload = data;

  if (gaId) {
    payload.utm_data = payload.utm_data || {};
    payload.utm_data.ga_id = gaId;
  }

  if (landing_page) {
    payload.lead_info_data = payload.lead_info_data || {};
    payload.lead_info_data.landing_page = landing_page;
  }

  yield put(actions.creatingQueueEntry());

  try {
    const response = yield call(NextServerAPI, {
      method: 'POST',
      path: '/api/leads/list/',
      data: payload,
    });

    yield put(actions.createdQueueEntry({ response }));
    action.callback && action.callback(response);
  } catch (e) {
    action.callback && action.callback();
    yield put(actions.errorCreatingQueueEntry({
      errorMessage: 'Error creating queue entry'
    }));
  }
}

export function* markAgentAvailability(action) {
  const { token, data } = action.payload;

  yield put(actions.markingAgentAvailability());

  try {
    const response = yield call(NextServerAPI, {
      method: 'PUT',
      AuthorizationToken: `Bearer ${token}`,
      path: '/api/agents/agent-availability/',
      data
    });

    yield put(actions.markedAgentAvailability({ response }));
  } catch (err) {
    yield put(actions.errorMarkingAgentAvailability());
  }
}

export function* initiateManualDial(action) {
  const { token, data } = action.payload;

  yield put(actions.initiatingManualDial({ lead: data.phoneNumber }));

  try {
    const response = yield call(NextServerAPI, {
      method: 'POST',
      AuthorizationToken: `Bearer ${token}`,
      path: '/api/leads/manual-dial/',
      data
    });

    yield put(actions.setManualDial({ isEnabled: response.data.manual_dial_status }));

    yield put(actions.initiatedManualDial({ response }));
  } catch (err) {
    yield put(actions.errorInitiatingManualDial());
  }
}

export function* createNotes(action) {
  const { token, data } = action.payload;

  yield put(actions.creatingNotes());

  try {
    const response = yield call(NextServerAPI, {
      method: 'POST',
      AuthorizationToken: `Bearer ${token}`,
      path: `/api/leads/notes/add/`,
      data,
    });

    toast.success('Catatan Telah Ditambah');

    yield put(actions.createdNotes({ response }));
  } catch (e) {
    const errorMessage = 'Something went wrong while creating notes!';
    toast.error(errorMessage);

    yield put(actions.errorCreatingNotes({ errorMessage }));
  }
}

export function* getNotesHistory(action) {
  const { leadId, token } = action.payload;

  yield put(actions.loadingNotesHistory());

  try {
    const response = yield call(NextServerAPI, {
      method: 'GET',
      AuthorizationToken: `Bearer ${token}`,
      path: `/api/leads/notes/${leadId}/`,
    });

    yield put(actions.loadedNotesHistory({ response }));
  } catch (e) {
    const errorMessage = 'Something went wrong while loading notes!';
    toast.error(errorMessage);

    yield put(actions.errorLoadingNotesHistory({ errorMessage }));
  }
}

export function* getAgentLeaderboard(action) {
  const { token, agentId } = action.payload;

  yield put(actions.loadingAgentLeaderboard());

  try {
    const response = yield call(NextServerAPI, {
      method: 'GET',
      AuthorizationToken: `Bearer ${token}`,
      path: '/api/agents/agent-ranking/',
    });

    yield put(actions.loadedAgentLeaderboard({ response, agentId }));
  } catch (e) {
    const errorMessage = 'Terjadi Kesalahan.';
    toast.error(errorMessage);

    yield put(actions.errorLoadingAgentLeaderboard({ errorMessage }));
  }
}

export function* getCallRecordingList(action) {
  const { token, agentId } = action.payload;

  yield put(actions.loadingCallRecordingList());

  try {
    let currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1);
    currentDate.setHours(17, 0, 0, 0);

    const callRecordingList = yield call(NextServerAPI, {
      method: 'GET',
      AuthorizationToken: `Bearer ${token}`,
      path: `/api/agents/calls/${agentId}/${currentDate.toISOString()}/`
    });

    yield put(actions.loadedCallRecordingList({ callRecordingList }));
  } catch (e) {
    const errorMessage = 'Error Loading Call Recording';
    toast.error(errorMessage);

    yield put(actions.errorLoadingCallRecordingList({ errorMesage }));
  }
}

export function* getCallRecordingByNumber(action) {
  const { token, phoneNumber } = action.payload;

  yield put(actions.loadingCallRecordingByNumber());

  try {
    const recordingList = yield call(NextServerAPI, {
      method: 'GET',
      AuthorizationToken: `Bearer ${token}`,
      path: `/api/agents/calls/phone-number/${phoneNumber}/`
    });

    yield put(actions.loadedCallRecordingByNumber({ recordingList }));
  } catch (e) {
    const errorMessage = 'Error Loading Call Recording';
    toast.error(errorMessage);

    yield put(actions.errorLoadingCallRecordingByNumber({ errorMesage }));
  }
}

export function* getAgentStatusList(action) {
  const { token } = action.payload;

  yield put(actions.loadingAgentStatus());

  try {
    const agentStatusList = yield call(NextServerAPI, {
      method: 'GET',
      AuthorizationToken: `Bearer ${token}`,
      path: `/api/agents/status/`,
    });

    yield put(actions.loadedAgentStatus({ agentStatusList }));
  } catch (e) {
    const errorMessage = 'Something went wrong while loading agents status!';
    toast.error(errorMessage);

    yield put(actions.errorLoadingAgentStatus({ errorMessage }));
  }
}

export function* getLeadListByAgentID(action) {
  const { token, agentId } = action.payload;

  yield put(actions.loadingLeadListByAgentid({ loading: true }));

  try {
    const response = yield call(NextServerAPI, {
      AuthorizationToken: `Bearer ${token}`,
      method: 'GET',
      path: `/api/agents/${agentId}/`,
    });

    action.callback && action.callback(response);

    yield put(actions.loadedLeadListByAgentid({ response }));
  } catch (e) {
    console.log(e);
  }
}

export function* getCallLogsByAgent(action) {
  const { token, agentId, page } = action.payload;

  yield put(actions.loadingCallLogsByAgent());

  try {
    const response = yield call(NextServerAPI, {
      AuthorizationToken: `Bearer ${token}`,
      method: 'GET',
      path: `/api/agents/call-logs/agents/${agentId}?page=${page}`,
    });

    action.callback && action.callback(response);
    yield put(actions.loadedCallLogsByAgent({ response }));
  } catch (e) {
    console.log(e);
  }
}

export function* getCallLogsByLead(action) {
  const { token, leadId, page } = action.payload;

  yield put(actions.loadingCallLogsByLead());

  try {
    const response = yield call(NextServerAPI, {
      AuthorizationToken: `Bearer ${token}`,
      method: 'GET',
      path: `/api/agents/call-logs/leads/${leadId}?page=${page}`,
    });

    action.callback && action.callback(response);
    yield put(actions.loadedCallLogsByLead({ response }));
  } catch (e) {
    console.log(e);
  }
}

export function* getLeadByLeadID(action) {
  const { token, leadId } = action.payload;

  yield put(actions.loadingLeadByLeadid({ loading: true }));

  try {
    const response = yield call(NextServerAPI, {
      AuthorizationToken: `Bearer ${token}`,
      method: 'GET',
      path: `/api/leads/${leadId}/`,
    });

    action.callback && action.callback(response);

    yield put(actions.loadedLeadByLeadid({ response }));
  } catch (e) {
    console.log(e);
  }
}

export function* sendMessage(action) {
  const { token, data } = action.payload;

  try {
    const response = yield call(NextServerAPI, {
      AuthorizationToken: `Bearer ${token}`,
      method: 'POST',
      path: `/api/agents/chat/send-message/`,
      data,
    });

    action.callback && action.callback(response);
  } catch (e) {
    console.log(e);
  }
}

export function* getMessageList(action) {
  const { token, leadId, pageNumber } = action.payload;

  try {
    const response = yield call(NextServerAPI, {
      AuthorizationToken: `Bearer ${token}`,
      method: 'GET',
      path: `/api/leads/message/${leadId}/${pageNumber || 1}/`,
    });

    yield put(actions.loadedMessageList({ response,leadId, pageNumber }));

    action.callback && action.callback(response);
  } catch (e) {
    console.log(e);
  }
}

export function* sendTemplate(action) {
  const { token, data } = action.payload;

  try {
    const response = yield call(NextServerAPI, {
      AuthorizationToken: `Bearer ${token}`,
      method: 'POST',
      path: `/api/leads/message/send-template/`,
      data,
    });

    action.callback && action.callback(response);
    toast.success('template terkirim');
    yield put(actions.sentTemplate({ response }));

  } catch (e) {
    action.callback && action.callback();
    console.log(e);
  }
}

export function* getAgentChatHistory(action) {
  const { token, agentId } = action.payload;

  yield put(actions.loadingAgentChatHistory({ laoding: true }));

  try {
    const response = yield call(NextServerAPI, {
      AuthorizationToken: `Bearer ${token}`,
      method: 'GET',
      path: `/api/agents/chat/${agentId}/`,
    });

    action.callback && action.callback(response);

    yield put(actions.loadedAgentChatHistory({ response }));
  } catch (e) {
    console.log(e);
  }
}

export function* sendProposal(action) {
  const { token, data } = action.payload;

  yield put(actions.sendingProposal({ laoding: true }));

  try {
    const response = yield call(NextServerAPI, {
      AuthorizationToken: `Bearer ${token}`,
      method: 'POST',
      path: `/api/agents/chat/send-proposal/`,
      data,
    });

    yield put(actions.sentProposal({ laoding: false }));

    action.callback && action.callback(response);
  } catch (e) {
    console.log(e);
  }
}

export default [
  [actionTypes.UPDATE_QUOTATION, updateQuotation],
  [actionTypes.GET_AGENT_QUOTATION_PLAN_AND_ORDER, getAgentQuotationPlanAndOrder],
  [actionTypes.UPDATE_PERSON, updatePerson],
  [actionTypes.UPDATE_POLICY_MEMBER, updatePolicyMember],
  [actionTypes.UPDATE_DECLARATION, updateDeclaration],
  [actionTypes.UPDATE_QUOTATION_STATUS, updateQuotationStatus],
  [actionTypes.GET_AGENT_MONTHLY_DATA, getAgentMonthlyData],
  [actionTypes.AGENT_DASHBOARD_SEARCH, agentDashboardSearch],
  [actionTypes.UPDATE_SCHEDULE, updateSchedule],
  [actionTypes.UPDATE_REFUND_ORDER, updateRefundOrder],
  [actionTypes.GET_PERSON, getPerson],
  [actionTypes.UPDATE_PERSONAL_ACCOUNT, updatePersonalAccount],
  [actionTypes.GET_PERSONAL_ACCOUNT, getPersonalAccount],
  [actionTypes.GET_LEAD_INFO, getLeadInfo],
  [actionTypes.GET_LEAD_QUOTATION_INFO, getLeadQuotationInfo],
  [actionTypes.GET_LEAD_LIST, getLeadList],
  [actionTypes.REJECT_LEAD, rejectLead],
  [actionTypes.UPDATE_QUEUE_SCHEDULE, updateQueueSchedule],
  [actionTypes.GET_BANK_ACCOUNT_DETAIL, getBankAccountDetail],
  [actionTypes.GET_BALANCE, getBalance],
  [actionTypes.PROCESS_WITHDRAWAL, processWithdrawal],
  [actionTypes.AUTHENTICATE_WITHDRAWAL, authenticateWithdrawal],
  [actionTypes.CREATE_LEAD, createLead],
  [actionTypes.GET_AGENT_LEVEL, getAgentLevel],
  [actionTypes.UPDATE_QUOTATIONS, updateQuotations],
  [actionTypes.GET_LEAD_SUMMARY_LIST, getLeadSummaryList],
  [actionTypes.GET_TRANSACTION_SUMMARY, getTransactionSummary],
  [actionTypes.GET_AGENT_PERFORMANCE, getAgentPerformance],
  [actionTypes.GET_AGENT_DETAIL, getAgentDetail],
  [actionTypes.GET_WITHDRAWAL_SUMMARY_LIST, getWithdrawalSummaryList],
  [actionTypes.GET_AGENT_DATA, getAgentData],
  [actionTypes.GET_AGENT_TEAMS, getAgentTeams],
  [actionTypes.GET_USER_DETAILS, getUserDetails],
  [actionTypes.CREATE_QUEUE_ENTRY, createQueueEntry],
  [actionTypes.MARK_AGENT_AVAILABILITY, markAgentAvailability],
  [actionTypes.INITIATE_MANUAL_DIAL, initiateManualDial],
  [actionTypes.CREATE_NOTES, createNotes],
  [actionTypes.GET_NOTES_HISTORY, getNotesHistory],
  [actionTypes.GET_AGENT_LEADERBOARD, getAgentLeaderboard],
  [actionTypes.GET_CALL_RECORDING_LIST, getCallRecordingList],
  [actionTypes.GET_CALL_RECORDING_BY_NUMBER, getCallRecordingByNumber], // 11
  [actionTypes.GET_AGENT_STATUS, getAgentStatusList],
  [actionTypes.GET_LEAD_LIST_BY_AGENTID, getLeadListByAgentID],
  [actionTypes.GET_CALL_LOGS_BY_AGENT, getCallLogsByAgent],
  [actionTypes.GET_CALL_LOGS_BY_LEAD, getCallLogsByLead],
  [actionTypes.GET_LEAD_BY_LEADID, getLeadByLeadID],
  [actionTypes.SEND_MESSAGE, sendMessage],
  [actionTypes.GET_MESSAGE_LIST, getMessageList],
  [actionTypes.SEND_TEMPLATE, sendTemplate],
  [actionTypes.GET_AGENT_CHAT_HISTORY, getAgentChatHistory],
  [actionTypes.SEND_PROPOSAL, sendProposal],
];

export const cleanPhoneNumber = (phone, withPrefix) => {
  const whiteSpace = /\s/g;
  let noLeadingNumber = /^(0|62)/g;
  let replacement = '';

  if (withPrefix) {
    noLeadingNumber = /^(62)/g;
    replacement = 0;
  }

  return phone.replace(whiteSpace, '').replace(noLeadingNumber, replacement);
};

export const isPhoneNumberValid = (phone, withPrefix) => {
  const phoneNumber = phone.replace(/\s/g, '');

  const specialChar = /\D+/g;
  const zeroPrefix = /^0/g;
  if (phoneNumber.match(specialChar) || phoneNumber.match(zeroPrefix)) {
    return false;
  }

  let validNumber = /^(?<operator>(81[1-9]|82[1-3]|83[1-3,8]|85[1-3,5-9]|87[7-9]|88[1,2,7-9]|89[5-9]))(?<number>\d{6,9})$/;

  if (withPrefix) {
    validNumber = /^0(?<operator>(81[1-9]|82[1-3]|83[1-3,8]|85[1-3,5-9]|87[7-9]|88[1,2,7-9]|89[5-9]))(?<number>\d{6,9})$/;
  }

  const repetition = /(\d)\1{5}/;
  const sequence = /(12345|23456|34567|45678|56789|67890|09876|98765|87654|76543|65432|54321)/;

  if (!phoneNumber.match(validNumber)) {
    return false;
  }

  if (phoneNumber.match(repetition) || phoneNumber.match(sequence)) {
    return false;
  }

  return true;
}

export const formatMobileNumber = (number) => `${number.slice(0, -4)}xxxx`;

import { call, put } from "redux-saga/effects";

import { NextServerAPI } from '../../../utils/coreServerAPI';
import actions, { actionTypes } from "../../../shell/actions";

export function* getInquiryPayment(action) {
  const { data } = action.payload;

  yield put(actions.loadingInquiryPayment());

  try {
    const response = yield call(NextServerAPI, {
      method: 'POST',
      path: `/api/orders/payments/inquiry/ayopop/`,
      data,
    });

    if (response.data.errorMessage) {
      yield put(actions.errorLoadingInquiryPayment({ errorMessage: response.data.errorMessage }));
    } else {
      yield put(actions.loadedInquiryPayment({ response }));
    }
  }
  catch (e) {
    yield put(actions.errorLoadingInquiryPayment({ errorMessage: 'Nomor BPJS Anda tidak dapat diproses.' }));
  }
}

export function* createDigitalOrder(action) {
  const { data, token, url } = action.payload;

  yield put(actions.creatingDigitalOrder());

  try {
    const response = yield call(NextServerAPI, {
      method: 'POST',
      path: url || `/api/orders/payments/digital-order/`,
      AuthorizationToken: `bearer ${token}`,
      data,
    });

    if (response.data.errorMessage) {
      yield put(actions.errorCreatingDigitalOrder({ errorMessage: response.data.errorMessage }));
    } else {
      yield put(actions.createdDigitalOrder({ response }));
    }
    action.callback && action.callback({ response });
  }
  catch (e) {
    yield put(actions.errorCreatingDigitalOrder({ errorMessage: 'Terjadi Kesalahan.' }));
    action.callback && action.callback({ data: { errorMessage: 'Terjadi Kesalahan.' } });
  }
}

export function* getCalculatePrice(action) {
  const { data } = action.payload;

  yield put(actions.loadingCalculatePrice());

  try {
    const response = yield call(NextServerAPI, {
      method: 'POST',
      path: `/api/produk-digital/calculate-price/`,
      data,
    });

    if (response.data.errorMessage) {
      yield put(actions.errorLoadingCalculatePrice({ errorMessage: response.data.errorMessage }));
      action.callback && action.callback({ success: false, errorMessage: response.data.errorMessage });
    } else {
      yield put(actions.loadedCalculatePrice({ response }));
      action.callback && action.callback({ success: true, response });
    }
  }
  catch (e) {
    yield put(actions.errorLoadingCalculatePrice({ errorMessage: 'Something went wrong' }));
    action.callback({ success: false, errorMessage: 'Something went wrong' });
  }
}

export function* getCarTaxHistories(action) {
  const { token } = action.payload;

  yield put(actions.loadingCarTaxHistories());

  try {
    const response = yield call(NextServerAPI, {
      method: 'GET',
      path: '/api/products/digital/car-tax/',
      AuthorizationToken: `Bearer ${token}`,
    });

    if (response.data.digitalProduct.length <= 0) {
      yield put(actions.errorLoadingCarTaxHistories({ errorMessage: 'Riwayat Transaksi Kosong.' }));
    }

    yield put(actions.loadedCarTaxHistories({ response }));
  } catch (e) {
    yield put(actions.errorLoadingCarTaxHistories({ errorMessage: e }));
  }
}

export default [
  [actionTypes.GET_INQUIRY_PAYMENT, getInquiryPayment],
  [actionTypes.CREATE_DIGITAL_ORDER, createDigitalOrder],
  [actionTypes.GET_CAR_TAX_HISTORIES, getCarTaxHistories],
  [actionTypes.GET_CALCULATE_PRICE, getCalculatePrice],
];

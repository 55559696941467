import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import commonReducers from '../common/reducers';
import accountReducers from '../modules/account/reducers';
import agentReducers from '../modules/agents/reducers';
import planReducers from '../modules/plans/reducers';
import quotationReducers from '../modules/quotations/reducers';
import orderReducers from '../modules/orders/reducers';
import digitalProductReducers from '../modules/digital-product/reducers';
import notificationReducers from '../modules/notification/reducers';

const agentPersistConfig = {
  key: 'agent',
  storage: storage,
  blacklist: ['callLogs'],
};

const planPersistConfig = {
  key: 'plan',
  storage: storage,
  whitelist: ['compareList']
};

const notificationPersistConfig = {
  key: 'notification',
  storage: storage,
};

const digitalPersistConfig = {
  key: 'digitalProduct',
  storage: storage,
  whitelist: ['calculatedPrice', 'mobilTaxFormValues']
};

const appReducer = combineReducers({
  shared: commonReducers,
  account: accountReducers,
  agent: persistReducer(agentPersistConfig, agentReducers),
  plan: persistReducer(planPersistConfig, planReducers),
  quotation: quotationReducers,
  order: orderReducers,
  digitalProduct: persistReducer(digitalPersistConfig, digitalProductReducers),
  notification: persistReducer(notificationPersistConfig, notificationReducers),
});

export const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === 'USER_LOGGED_OUT') {
    storage.removeItem('persist:agent');
    storage.removeItem('persist:digitalProduct');
    state = undefined;
  }

  return appReducer(state, action);
};

import { call, put } from "redux-saga/effects";
import qs from 'querystring';

import { NextServerAPI } from '../../../utils/coreServerAPI';
import actions, { actionTypes } from "../../../shell/actions";
import { cleanPhoneNumber } from '../../../../lib/phoneNumber';

export function *userLogin (action) {
  const { emailOrPhoneNumber, password } = action.payload;

  yield put(actions.loggingIn());

  let username = emailOrPhoneNumber;
  if (!isNaN(emailOrPhoneNumber)) {
    username = cleanPhoneNumber(emailOrPhoneNumber);
  }

  try {
    const response = yield call(NextServerAPI, {
      method: 'POST',
      path: `/api/user/login/`,
      data: {
        username,
        password
      }
    });

    yield put(actions.loggedIn({ response }));
    if (action.callback) {
      action.callback(response);
    }
  } catch (error) {
    let errorMessage = 'Username atau password salah';

    if (error.message.includes('404')) {
      errorMessage = 'Akun tidak di temukan';
    }

    yield put(actions.errorLoggingIn({ errorMessage: errorMessage }));
  }
}

export function *userRegister (action) {
  const { email, password, fullName, phoneNumber, isAgent, registrationCode, parentId, organizationId } = action.payload;
  const name = fullName.split(' ');
  const phone = phoneNumber.replace(/\s/g, '');

  yield put(actions.registering());

  const data = {
    password,
    username: phone,
    phone_number: phone,
    last_name: name.length > 1 ? name.pop() : '',
    first_name: name.join(' '),
    source_type: 'newsletter_register',
    ...(registrationCode ? { registration_code : registrationCode} : {}),
    ...(parentId ? { parent_id: parentId} : {}),
    ...(organizationId ? { organization_id: organizationId} : {}),
  };

  if (email) {
    data.email = email;
  }

  try {
    const response = yield call(NextServerAPI, {
      method: 'POST',
      path: isAgent ? `/api/user/register/?isAgent=true` : '/api/user/register/',
      data,
     });

    if (action.callback) {
      action.callback(response);
    }
     
     if (response.data.status) {
       yield put(actions.errorRegistering({ errorMessage: 'Akun sudah terdaftar.' }));
     } else {
       yield put(actions.registeredUser({ response }));
     }
  } catch (error) {
    yield put(actions.errorRegistering({ errorMessage: 'Akun sudah terdaftar.' }));
  }
}

export function *changePassword (action) {
  const { oldPassword, newPassword, passwordConfirmation, token } = action.payload;

  yield put(actions.changingPassword());

  const status = {
    isSuccess: false,
    errorMessage: '',
  };

  try {
    yield call(NextServerAPI, {
      method: 'POST',
      path: '/api/user/password/change/',
      data: {
        old_password: oldPassword,
        new_password: newPassword,
        confirm_password: passwordConfirmation,
      },
      AuthorizationToken: `Bearer ${token}`,
    });

    status.isSuccess = true;
    yield put(actions.changedPassword());
  } catch (error) {
    status.isSuccess = false;
    status.errorMessage = 'Terjadi kesalahan';
    yield put(actions.errorChangingPassword({ errorMessage: 'Terjadi kesalahan' }));
  }

  if (action.callback) {
    action.callback(status);
  }
}

export function *getOrderHistory (action) {
  const { data, token } = action.payload;

  yield put(actions.loadingOrderHistory());

  try {
    const response = yield call(NextServerAPI, {
      method: 'GET',
      path: `/api/orders/history/?limit=100&${qs.stringify(data)}`,
      AuthorizationToken: `bearer ${token}`,
    });

    yield put(actions.loadedOrderHistory({ response }));
  } catch (error) {
    yield put(actions.errorLoadingOrderHistory());
  }
}

export default [
  [actionTypes.USER_LOGIN, userLogin],
  [actionTypes.USER_REGISTER, userRegister],
  [actionTypes.CHANGE_PASSWORD, changePassword],
  [actionTypes.GET_ORDER_HISTORY, getOrderHistory],
];

import getConfig from 'next/config';

import * as Sentry from '@sentry/nextjs';

const { SENTRY_DSN, NODE_ENV, IMAGE_VERSION } = getConfig().publicRuntimeConfig;

if (['production', 'staging'].includes(NODE_ENV)) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: NODE_ENV,
    release: IMAGE_VERSION,
    tracesSampleRate: 1.0,
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications.',
      'Session does not exist.',
      'AbortError: The play() request was interrupted by a call to pause(). https://goo.gl/LdLk22',
      'AbortError: The play() request was interrupted by a call to pause().',
      'AbortError: The play() request was interrupted by a new load request.',
      'AbortError: The play() request was interrupted by a new load request. https://goo.gl/LdLk22',
      'NotAllowedError: play() failed because the user didn\'t interact with the document first.https://goo.gl/xX8pDD',
    ],
  });
}

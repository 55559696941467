import { actionTypes } from "../../shell/actions";
import get from 'lodash/get';

const deafultState = {
  token: '',
  loading: false,
  error: false,
  birthPlaces: [], // TODO: remove redux when no component use anymore
  regencies: [], // TODO: remove redux when no component use anymore
  villages: [], // TODO: remove redux when no component use anymore
  plan: {},
  order: {},
  banks: [],
  paymentMethods: [],
  tenure: [],
  leadResponse: {},
  navigationItems: [],
  locationList: [],
  paymentMethodGroups: {},
  relatedArticles: [],
  fileUploadProgress: '',
};

/**
 *
 * @param {Object} state - Redux state
 * @param {Object} response - server response
 */

function normalizeDataList(dataList = []) {
  return dataList.map(row => ({ label: row.name, value: row.id }));
}

function normalizePaymentMethods(dataList = []) {
  const installmentObject = dataList.find(item => item.paymentMethod === 'INSTALLMENT');
  const paymentMethod = dataList
    .filter(item => item.paymentMethod !== 'INSTALLMENT')
    .map(item => ({
      label: item.displayName,
      value: `${item.paymentMethod}-${item.paymentProvider}`,
      paymentMethod: item.paymentMethod,
      paymentProvider: item.paymentProvider,
      feeType: item.feeType,
      feeAmount: item.feeAmount,
    }));

  if (installmentObject) {
    paymentMethod.push({
      label: 'Kartu Kredit Cicilan',
      value: `${installmentObject.paymentMethod}-${installmentObject.paymentProvider}`,
      paymentMethod: installmentObject.paymentMethod,
      paymentProvider: installmentObject.paymentProvider,
    });
  }
  return paymentMethod;
}

function getPaymentMethodGroups(dataList = []) {
  let installmentCounter = 0;

  return dataList
    .filter(row => row.isActive)
    .sort()
    .reduce((acc, row) => {
      const paymentMethodDetail = {
        paymentMethod: row.paymentMethod,
        paymentProvider: row.paymentProvider,
        children: [],
      };

      if (row.paymentMethod === 'INSTALLMENT' && installmentCounter < 1) {
        acc['Cicilan'] = {
          ...paymentMethodDetail,
          children: getBankInstallments(dataList),
        };
        installmentCounter += 1;
      } else if (row.paymentMethod.includes('_VA')) {
        if (!acc['Transfer Bank']) {
          acc['Transfer Bank'] = paymentMethodDetail;
        }
        acc['Transfer Bank'].children.push(row);
      } else if (['DANA', 'LINKAJA', 'OVO', 'GOPAY', 'RECURRING'].includes(row.paymentMethod)) {
        if (!acc['E-Wallet']) {
          acc['E-Wallet'] = paymentMethodDetail;
        }
        acc['E-Wallet'].children.push(row);
      } else if (['ALFAMART', 'INDOMARET'].includes(row.paymentMethod)) {
        if (!acc['Minimarket']) {
          acc['Minimarket'] = paymentMethodDetail;
        }
        acc['Minimarket'].children.push(row);
      } else if (['KREDIVO', 'AKULAKU', 'INDODANA'].includes(row.paymentMethod)) {
        if (!acc['Cicilan Tanpa Kartu Kredit']) {
          acc['Cicilan Tanpa Kartu Kredit'] = paymentMethodDetail;
        }
        acc['Cicilan Tanpa Kartu Kredit'].children.push(row);
      } else if (['CIMB_CLICK', 'DANAMON_IB', 'BRI_EPAY', 'BCA_KLIKPAY'].includes(row.paymentMethod)) {
        if (!acc['Online Payment']) {
          acc['Online Payment'] = paymentMethodDetail;
        }
        acc['Online Payment'].children.push(row);
      } else if (row.paymentMethod === 'QRIS') {
        if (!acc['QRIS']) {
          acc['QRIS'] = paymentMethodDetail;
        }
        acc['QRIS'].children.push(row);
      } else if (row.paymentMethod.includes('AUTODEBIT_')) {
        if (!acc['Direct Debit']) {
          acc['Direct Debit'] = paymentMethodDetail;
        }
        acc['Direct Debit'].children.push(row);
      } else if (row.paymentMethod === 'AUTODEBIT') {
        if (!acc['Autodebit']) {
          acc['Autodebit'] = paymentMethodDetail;
        }
      } else if (row.paymentMethod === 'CREDIT_CARD') {
        if (!acc['Kartu Kredit / Kartu Debit']) {
          acc['Kartu Kredit / Kartu Debit'] = paymentMethodDetail;
        }
      } else if (row.paymentMethod === 'RECURRING_MOTO') {
        if (!acc['Recurring Moto']) {
          acc['Recurring Moto'] = paymentMethodDetail;
        }
      } else if (row.paymentMethod === 'SEND_PAYMENT_LINK') {
        if (!acc['Send Payment Link']) {
          acc['Send Payment Link'] = paymentMethodDetail;
        }
      }
      return acc;
    }, {});
}

function getBankInstallments(dataList = []) {
  let bankInstallments = [];

  dataList
    .filter(row => row.paymentMethod === 'INSTALLMENT' && row.isActive)
    .forEach(data => {
      data.bankInstallments.forEach(installment => {
        const foundIndex = bankInstallments.findIndex(installmentChild => {
          return installmentChild.displayName === installment.display && !installmentChild.tenures.includes(installment.tenure);
        });

        if (foundIndex < 0) {
          bankInstallments.push({
            paymentMethod: installment.bankName,
            paymentProvider: installment.provider,
            displayName: installment.display,
            tenures: [{
              promoId: installment.promoId,
              tenure: installment.tenure,
              feeAmount: data.feeAmount,
              feeType: data.feeType,
            }],
          });
          return;
        }

        bankInstallments[foundIndex].tenures.push({
          promoId: installment.promoId,
          tenure: installment.tenure,
          feeAmount: data.feeAmount,
          feeType: data.feeType,
        });
      });
    });

  return bankInstallments;
}

function getInstallmentTenure(dataList = []) {
  return dataList
    .filter(item => item.paymentMethod === 'INSTALLMENT')
    .map(item => ({
      name: 'tenure',
      value: Number(item.displayName.replace(/\D/g, "")),
      label: `${item.displayName.replace(/\D/g, "")} bulan`,
      bankInstallments: item.bankInstallments,
      feeType: item.feeType,
      feeAmount: item.feeAmount,
    }));
}

function normalizeRelatedArticles(dataList = []) {
  if (!dataList.length) return dataList;

  return dataList.map(item => ({
    title: get(item, 'title', ''),
    slug: get(item, 'slug', ''),
  }));
}


export default function sharedReducers(state = deafultState, action) {
  switch (action.type) {
    case actionTypes.LOADING_BIRTH_PLACES:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.LOADED_BIRTH_PLACES:
      return {
        ...state,
        loading: false,
        birthPlaces: normalizeDataList(action.payload.response.data),
      }
    case actionTypes.ERROR_LOADING_BIRTH_PLACES:
      return {
        ...state,
        loading: false,
        error: true,
      }
    case actionTypes.LOADING_REGENCIES:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.LOADED_REGENCIES:
      return {
        ...state,
        loading: false,
        regencies: normalizeDataList(action.payload.response.data),
      }
    case actionTypes.ERROR_LOADING_REGENCIES:
      return {
        ...state,
        loading: false,
        error: true,
      }
    case actionTypes.LOADING_VILLAGES:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.LOADED_VILLAGES:
      return {
        ...state,
        loading: false,
        villages: normalizeDataList(action.payload.response.data),
      }
    case actionTypes.ERROR_LOADING_VILLAGES:
      return {
        ...state,
        loading: false,
        error: true,
      }
    case actionTypes.LOADING_BANKS:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.LOADED_BANKS:
      const normalizeBanks = action.payload.banks.data.map(row => ({
        label: row.bankName,
        value: row.bankCode,
        code: row.xenditBankCode,
      }));

      return {
        ...state,
        loading: false,
        banks: normalizeBanks,
      }
    case actionTypes.ERROR_LOADING_BANKS:
      return {
        ...state,
        loading: false,
        error: true,
      }

    case actionTypes.LOADING_PAYMENT_METHODS:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.LOADED_PAYMENT_METHODS:
      return {
        ...state,
        loading: false,
        paymentMethods: normalizePaymentMethods(action.payload.paymentMethods.data),
        tenure: getInstallmentTenure(action.payload.paymentMethods.data),
        paymentMethodGroups: getPaymentMethodGroups(action.payload.paymentMethods.data),
      }
    case actionTypes.ERROR_LOADING_PAYMENT_METHODS:
      return {
        ...state,
        loading: false,
        error: true,
      }

    case actionTypes.SUBMITING_LEADS:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.SUBMITTED_LEADS:
      return {
        ...state,
        loading: false,
        leadResponse: action.payload.leads.data,
      }
    case actionTypes.ERROR_SUBMITTING_LEADS:
      return {
        ...state,
        loading: false,
        error: true,
      }
    case actionTypes.RESET_LEADS:
      return {
        ...state,
        leadResponse: {},
      }
    case actionTypes.LOADING_NAVIGATION_ITEMS:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.LOADED_NAVIGATION_ITEMS:
      return {
        ...state,
        loading: false,
        navigationItems: action.payload.navigationItems.data,
      }
    case actionTypes.ERROR_LOADING_NAVIGATION_ITEMS:
      return {
        ...state,
        loading: false,
        error: true,
      }
    case actionTypes.LOADING_LOCATION_LIST:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.LOADED_LOCATION_LIST:
      return {
        ...state,
        loading: false,
        locationList: action.payload.locations.data,
      }
    case actionTypes.ERROR_LOADING_LOCATION_LIST:
      return {
        ...state,
        loading: false,
        error: true,
      }
    case actionTypes.RESET_LOCATION_LIST:
      return {
        ...state,
        locationList: [],
      }
    case actionTypes.RESET_LEAD_RESPONSE:
      return {
        ...state,
        leadResponse: {},
      }
    case actionTypes.SUBMITTING_FINANCIAL_CHECKUP:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.SUBMITTED_FINANCIAL_CHECKUP:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.ERROR_SUBMITTING_FINANCIAL_CHECKUP:
      return {
        ...state,
        loading: false,
        error: true,
      }
    case actionTypes.LOADING_RELATED_ARTICLES:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.LOADED_RELATED_ARTICLES:
      return {
        ...state,
        loading: false,
        relatedArticles: normalizeRelatedArticles(action.payload),
      }
    case actionTypes.ERROR_LOADING_RELATED_ARTICLES:
      return {
        ...state,
        loading: false,
        error: true,
      }
    case actionTypes.LOADING_CALCULATOR_ARTICLES:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.LOADED_CALCULATOR_ARTICLES:
      return {
        ...state,
        loading: false,
        calculatorArticles: normalizeRelatedArticles(action.payload),
      }
    case actionTypes.ERROR_LOADING_CALCULATOR_ARTICLES:
      return {
        ...state,
        loading: false,
        error: true,
      }
    case actionTypes.UPLOAD_FILES_START:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.UPLOAD_FILES_SUCCESS:
      return {
        ...state,
        loading: false,
        fileUploadProgress: ''
      }
    case actionTypes.UPLOAD_FILES_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        fileUploadProgress: ''
      }
    case actionTypes.UPLOAD_FILES_PROGRESS:
      return {
        ...state,
        fileUploadProgress: action.payload
      }
    default:
      return state;
  }
}

import { actionTypes } from "../../../shell/actions";
import cookie from'js-cookie';

const deafultState = {
  token: '',
  loading: false,
  error: false,
  errorMessage: '',
  agentRegistered: {},
  userRegistered: {},
  successChangingPassword: false,
  orderHistory: {},
};

/**
 *
 * @param {Object} state - Redux state
 * @param {Object} response - server response
 */

export default function accountReducers (state = deafultState, action) {
  switch (action.type) {
    case actionTypes.LOADING_SAMPLE_DATA:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOADED_SAMPLE_DATA:
      return {
        ...state,
        loading: false
      };
    case actionTypes.LOADING_ORDER_HISTORY:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOADED_ORDER_HISTORY:
      return {
        ...state,
        orderHistory: action.payload.response.data,
        loading: false
      };
    case actionTypes.ERROR_LOADING_ORDER_HISTORY:
      return {
        ...state,
        error: true,
        loading: false
      };
    case actionTypes.LOGGING_IN:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOGGED_IN:
      window.localStorage.setItem('expandedLead', []);
      return {
        ...state,
        token: action.payload.response.data.access,
        loading: false
      };
    case actionTypes.ERROR_LOGGING_IN:
      return {
        ...state,
        error: true,
        errorMessage: action.payload.errorMessage,
        loading: false
      };
    case actionTypes.REGISTERING:
      return {
        ...state,
        loading: true
      };
    case actionTypes.REGISTERED_AGENT:
      return {
        ...state,
        agentRegistered: action.payload.response.data,
        loading: false
      };
    case actionTypes.REGISTERED_USER:
      return {
        ...state,
        userRegistered: action.payload.response.data,
        loading: false
      };
    case actionTypes.ERROR_REGISTERING:
      return {
        ...state,
        error: true,
        errorMessage: action.payload.errorMessage,
        loading: false
      };
    case actionTypes.CHANGING_PASSWORD:
      return {
        ...state,
        loading: true
      };
    case actionTypes.CHANGED_PASSWORD:
      return {
        ...state,
        loading: false,
        successChangingPassword: true,
      };
    case actionTypes.ERROR_CHANGING_PASSWORD:
      return {
        ...state,
        error: true,
        errorMessage: action.payload.errorMessage,
        loading: false
      };
    case actionTypes.RESET_CHANGING_PASSWORD:
      return {
        ...state,
        error: false,
        successChangingPassword: false,
      };
    case actionTypes.LOG_OUT:
      cookie.remove('token');
      cookie.set('mode', 'user');

      localStorage.removeItem('digitalProductData');
      localStorage.removeItem('specification');
      localStorage.removeItem('orderId');
      localStorage.removeItem('queuedCalls');
      localStorage.removeItem('plan');
      localStorage.removeItem('queueId');
      localStorage.removeItem('currentStep');
      localStorage.removeItem('isUserReadInstructions');
      localStorage.removeItem('superVisorPusherClient');
      localStorage.removeItem('statusList');
      localStorage.removeItem('quote');
      localStorage.removeItem('members');
      localStorage.removeItem('callingVendor');
      localStorage.removeItem('isAutoDialEnabled');
      localStorage.removeItem('isManualDialEnabled');
      localStorage.removeItem('isConnectedToEventSource');
      localStorage.removeItem('isNewLeadAssigned');
      localStorage.removeItem('isInstantMessageConnected');
      localStorage.removeItem('leadList');
      localStorage.removeItem('expandedLead');
      localStorage.removeItem('unSuccessfulCallCounter');
      localStorage.removeItem('isAutoStarted');

      return {
        ...state,
        token: '',
      }
    default:
      return state;
  }
}

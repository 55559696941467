import { actionTypes } from "../../../shell/actions";

const defaultState = {
  loading: false,
  error: false,
  errorMessage: '',
  orderDetail: {},
  outstandingPayments: [],
  paymentResult: {},
  initiatePaymentResponse: {},
  transactionStatus: {},
  checkoutData: {
    quotation: {},
    plan: {},
    mainInsured: {},
    holder: {},
    buyer: {},
  },
  latestPayment: {},
};

/**
 *
 * @param {Object} state - Redux state
 * @param {Object} response - server response
 */

export default function orderReducers(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.LOADING_ORDER:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOADED_ORDER:
      return {
        ...state,
        orderDetail: action.payload.response.data,
        loading: false
      };
    case actionTypes.ERROR_LOADING_ORDER:
      return {
        ...state,
        error: true,
        loading: false
      };
    case actionTypes.LOADING_OUTSTANDING_PAYMENTS:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOADED_OUTSTANDING_PAYMENTS:
      return {
        ...state,
        outstandingPayments: action.payload.response.data.listAllPayments,
        loading: false
      };
    case actionTypes.ERROR_LOADING_OUTSTANDING_PAYMENTS:
      return {
        ...state,
        error: true,
        loading: false
      };
    case actionTypes.PAYING_OUTSTANDING_TRANSACTIONS:
      return {
        ...state,
        loading: true
      };
    case actionTypes.PAID_OUTSTANDING_TRANSACTIONS:
      return {
        ...state,
        paymentResult: action.payload.response.data,
        loading: false
      };
    case actionTypes.ERROR_PAYING_OUTSTANDING_TRANSACTIONS:
      return {
        ...state,
        error: true,
        loading: false,
        errorMessage: action.payload.errorMessage,
      };
    case actionTypes.INITIATING_PAYMENT:
      return {
        ...state,
        loading: true
      };
    case actionTypes.INITIATED_PAYMENT:
      return {
        ...state,
        initiatePaymentResponse: action.payload.response.data,
        loading: false
      };
    case actionTypes.ERROR_INITIATING_PAYMENT:
      return {
        ...state,
        error: true,
        loading: false,
        errorMessage: action.payload.errorMessage,
      };
    case actionTypes.RESET_INITIATE_PAYMENT_RESPONSE:
      return {
        ...state,
        initiatePaymentResponse: {},
      };
    case actionTypes.RESET_PAYMENT_ERROR_MESSAGE:
      return {
        ...state,
        error: false,
        errorMessage: '',
      };
    case actionTypes.LOADING_TRANSACTION_STATUS:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOADED_TRANSACTION_STATUS:
      return {
        ...state,
        transactionStatus: action.payload.response.data,
        loading: false
      };
    case actionTypes.ERROR_LOADING_TRANSACTION_STATUS:
      return {
        ...state,
        error: true,
        loading: false,
        errorMessage: action.payload.errorMessage,
      };
    case actionTypes.LOADING_CHECKOUT_DATA:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOADED_CHECKOUT_DATA:
      return {
        ...state,
        checkoutData: {
          quotation: action.payload.quotation.data,
          plan: action.payload.plan.data,
          mainInsured: action.payload.mainInsured,
          holder: action.payload.holder,
          buyer: action.payload.buyer,
        },
        loading: false
      };
    case actionTypes.ERROR_LOADING_CHECKOUT_DATA:
      return {
        ...state,
        error: true,
        loading: false,
        errorMessage: action.payload.errorMessage,
      };
    case actionTypes.LOADING_ORDER_BY_QUOTATION_ID:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOADED_ORDER_BY_QUOTATION_ID:
      return {
        ...state,
        orderDetail: action.payload.response.data,
        loading: false,
      };
    case actionTypes.ERROR_LOADING_ORDER_BY_QUOTATION_ID:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case actionTypes.SENDING_POLICY:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.SENT_POLICY:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.ERROR_SENDING_POLICY:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case actionTypes.ADDING_PAYMENT_LINK_VOUCHER:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.ADDED_PAYMENT_LINK_VOUCHER:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.ERROR_ADDING_PAYMENT_LINK_VOUCHER:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case actionTypes.REMOVING_PAYMENT_LINK_VOUCHER:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.REMOVED_PAYMENT_LINK_VOUCHER:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.ERROR_REMOVING_PAYMENT_LINK_VOUCHER:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case actionTypes.LOADING_LATEST_PAYMENT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.LOADED_LATEST_PAYMENT:
      return {
        ...state,
        loading: false,
        latestPayment: action.payload.response.data,
      };
    case actionTypes.ERROR_LOADING_LATEST_PAYMENT:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case actionTypes.DUPLICATING_ORDER:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DUPLICATED_ORDER:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.ERROR_DUPLICATING_ORDER:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case actionTypes.LOADING_QUOTATION_SLIP:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.LOADED_QUOTATION_SLIP:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.ERROR_LOADING_QUOTATION_SLIP:
      return {
        ...state,
        error: true,
        loading: false,
      };
    default:
      return state;
  }
} 
import React, { useState, useEffect } from 'react';

export const useScript = (dependency) => {

	const [loadedScriptId, setLoadedScriptId] = useState();

	const loadScript = (urlConfig) => {
		return new Promise((resolve, reject) => {
			const isScriptLoadedAlready = document.getElementById(urlConfig.id);
			if (isScriptLoadedAlready) {
				resolve(urlConfig.id);
			} else {
				const script = document.createElement('script');

				script.src = urlConfig.url;
				script.async = true;
				script.id = urlConfig.id;

				script.addEventListener('load', () => {
					resolve(urlConfig.id);
				});

				script.addEventListener('onError', () => {
					reject(true);
				});

				document.body.appendChild(script);
			}
		});
	};

	const processScript = async (urlConfig) => {
		let script = await loadScript(urlConfig);
		setLoadedScriptId(script);
		return script;
	};

	useEffect(() => {
		if (dependency && Object.keys(dependency).length) {
			processScript(dependency);
		}
		return () => {
			if (loadedScriptId) {
				document.body.removeChild(document.getElementById(loadedScriptId));
			}
		}
	}, []);
};

import { call, put } from "redux-saga/effects";

import { toast } from 'react-toastify';
import get from 'lodash/get';
import qs from 'query-string';

import { NextServerAPI } from '../../../utils/coreServerAPI';
import { getPolicyMembers } from '../../../../lib/createMember';
import actions, { actionTypes } from "../../../shell/actions";

export function* getQuotation(action) {
  const { data, token } = action.payload;

  yield put(actions.loadingQuotation());

  try {
    const response = yield call(NextServerAPI, {
      method: 'GET',
      AuthorizationToken: `bearer ${token}`,
      path: `/api/quotations/?${qs.stringify(data)}`
    });

    action.callback && action.callback(response);

    yield put(actions.loadedQuotation({ response }));
  }
  catch (e) {
    console.error('error: ', e);
    toast.error('Terjadi kesalahan, silahkan coba lagi.');
    yield put(actions.errorLoadingQuotation({ errorObject: e }));
  }
}

export function* submitQuotation(action) {
  const { data, token, buyerPhone } = action.payload;

  yield put(actions.submittingQuotation());

  try {
    const response = yield call(NextServerAPI, {
      method: 'POST',
      AuthorizationToken: `bearer ${token}`,
      path: `/api/quotations/${buyerPhone ? `?buyerPhone=${buyerPhone}` : ''}`,
      data,
    });

    action.callback && action.callback(response);

    if (response && response.data && response.data.code) {
      yield put(actions.errorSubmittingQuotation({ errorMessage: response.data.message }));
    } else {
      yield put(actions.submittedQuotation({ response }));
    }
  } catch (e) {
    yield put(actions.errorSubmittingQuotation({ errorMessage: 'Terjadi Kesalah.' }));
  }
}

export function* getPlanAndProduct(action) {
  const { token, query, isAuto } = action.payload;

  yield put(actions.loadingPlanAndProduct());

  try {
    const plan = yield call(NextServerAPI, {
      method: 'GET',
      path: `/api/products/plans/?${qs.stringify(query)}`,
      ...(token && {
        AuthorizationToken: `bearer ${token}`,
      }),
    });

    yield put(actions.loadedPlan({ plan }));

    if (plan.data && plan.data.status !== 404) {
      const declarations = yield call(NextServerAPI, {
        method: 'GET',
        path: `/api/products/${plan.data.product.id}/declarations/`,
      });

      let additionalBenefits = { data: [] };
      let autoRangePrice = { data: [] };

      if (isAuto) {
        const { name, year } = query;
        const [brand, model, type] = name.split('_');

        additionalBenefits = yield call(NextServerAPI, {
          method: 'GET',
          path: `/api/products/additional-benefits/?${qs.stringify(query)}`
        });

        autoRangePrice = yield call(NextServerAPI, {
          method: 'GET',
          path: `/api/products/auto/range-price/?year=${year}&brand_name=${encodeURIComponent(brand)}&model_name=${encodeURIComponent(model)}&type_name=${encodeURIComponent(type)}`,
        });
      }

      yield put(actions.loadedPlanAndProduct({ declarations, additionalBenefits, autoRangePrice }));
    }
  } catch (error) {
    console.error(error, 'a');
    toast.error('Terjadi kesalahan, silahkan coba lagi.');
    yield put(actions.errorLoadingPlanAndProduct());
  }
}

export function* getBenefitsAndTsi(action) {
  const { query, brand, model, type, year } = action.payload;

  yield put(actions.loadingBenefitsAndTsi());

  try {
    const additionalBenefits = yield call(NextServerAPI, {
      method: 'GET',
      path: `/api/products/additional-benefits/?${qs.stringify(query)}`
    });

    const autoRangePrice = yield call(NextServerAPI, {
      method: 'GET',
      path: `/api/products/auto/range-price/?year=${year}&brand_name=${encodeURIComponent(brand)}&model_name=${encodeURIComponent(model)}&type_name=${encodeURIComponent(type)}`,
    });

    yield put(actions.loadedBenefitsAndTsi({ additionalBenefits, autoRangePrice }));
  } catch (error) {
    yield put(actions.errorLoadingBenefitsAndTsi({ errorObject: error }));
  }
}

export function* getQuotationHistory(action) {
  const { data, token } = action.payload;

  yield put(actions.loadingQuotationHistory());

  try {
    const response = yield call(NextServerAPI, {
      method: 'GET',
      AuthorizationToken: `bearer ${token}`,
      path: `/api/quotations/history/?${qs.stringify(data)}`,
    });

    yield put(actions.loadedQuotationHistory({ response }));
  } catch (error) {
    yield put(actions.errorLoadingQuotationHistory({ errorObject: error }));
  }
}

export function* getExistingMember(action) {
  const { token } = action.payload;

  yield put(actions.loadingExistingMember());
  try {
    const response = yield call(NextServerAPI, {
      method: 'GET',
      AuthorizationToken: `bearer ${token}`,
      path: `/api/quotations/user/persons/`,
    });

    yield put(actions.loadedExistingMember({ response }));
  } catch (error) {
    yield put(actions.errorLoadingExistingMember({ errorObject: error }));
  }
}

export function* getExistingUsers(action) {
  const { phoneNumber, token } = action.payload;

  yield put(actions.loadingExistingUsers());

  try {
    const response = yield call(NextServerAPI, {
      method: 'GET',
      AuthorizationToken: `bearer ${token}`,
      path: `/api/quotations/user/phone-number/${phoneNumber}/`,
    });

    yield put(actions.loadedExistingUsers({ response }));
  } catch (error) {
    yield put(actions.errorLoadingExistingUsers());
  }
}

export function* getPlan(action) {
  const { query, token } = action.payload;

  yield put(actions.loadingPlan());

  try {
    const plan = yield call(NextServerAPI, {
      method: 'GET',
      path: `/api/products/plans/?${qs.stringify(query)}`,
      AuthorizationToken: `bearer ${token}`,
    });

    action.callback && action.callback(plan);
    yield put(actions.loadedPlan({ plan }));
  } catch (error) {
    yield put(actions.errorLoadingPlan());
  }
}

export function* getUserProfile(action) {
  const { token } = action.payload;

  yield put(actions.loadingUserProfile());

  try {
    const userProfile = yield call(NextServerAPI, {
      method: 'GET',
      AuthorizationToken: `bearer ${token}`,
      path: `/api/quotations/user/profile/`
    });

    action.callback && action.callback(userProfile.data);

    yield put(actions.loadedUserProfile({ userProfile }));
  } catch (error) {
    yield put(actions.errorLoadingUserProfile());
  }
}

export function* getRelatedPlansWithQuotationId(action) {
  const { token, query } = action.payload;

  yield put(actions.loadingRelatedPlans());

  try {
    const quotation = yield call(NextServerAPI, {
      method: 'GET',
      AuthorizationToken: `bearer ${token}`,
      path: `/api/quotations/?${qs.stringify(query)}`,
    });

    const memberList = get(quotation, 'data.policyMembers', []).filter(row => (row.person && row.type === 'INSURED'));
    const duration = get(quotation, 'data.declaration.duration', 0);
    const members = memberList.length ? JSON.stringify(getPolicyMembers(memberList, duration)) : null;

    const queryPlans = {
      product_id: query.product_id,
      category: query.category,
      member: members
    };

    const response = yield call(NextServerAPI, {
      method: 'GET',
      path: `/api/products/plans/?${qs.stringify(queryPlans)}`,
      AuthorizationToken: `bearer ${token}`,
    });

    yield put(actions.loadedRelatedPlans({ response }))
  } catch (error) {
    yield put(actions.errorLoadingRelatedPlans());
  }
}

export function* deleteMember(action) {
  const { token, data } = action.payload;

  try {
    yield call(NextServerAPI, {
      method: 'DELETE',
      AuthorizationToken: `bearer ${token}`,
      path: `/api/quotations/policy-members/`,
      data,
    });
  } catch (error) {
    toast.error('Terjadi Kesalahan');
  }
}

export function* updateStatus(action) {
  const { id, token, data } = action.payload;

  yield put(actions.updatingStatus());

  try {
    yield call(NextServerAPI, {
      method: 'PATCH',
      AuthorizationToken: `bearer ${token}`,
      path: `/api/quotations/history/?quotationId=${id}`,
      data,
    });

    yield put(actions.updatedStatus());
  } catch (error) {
    toast.error('Terjadi Kesalahan');
    yield put(actions.errorUpdatingStatus());
  }
}

export function* savePerson(action) {
  const { token, data } = action.payload;

  yield put(actions.savingPerson());

  try {
    const person = yield call(NextServerAPI, {
      method: 'POST',
      AuthorizationToken: `bearer ${token}`,
      path: '/api/quotations/persons/',
      data,
    });

    if (person && person.data && person.data.id) {
      action.callback && action.callback(person.data.id);
    }

  } catch (error) {
    toast.error('Terjadi Kesalahan');
    yield put(actions.errorSavingPerson());
  }
}

export function* getPersonsByPhoneNumber(action) {
  const { token, phoneNumber } = action.payload;

  yield put(actions.loadingPersonsByPhoneNumber());

  try {
    const response = yield call(NextServerAPI, {
      method: 'GET',
      path: `/api/quotations/persons/${phoneNumber}/`,
      AuthorizationToken: `bearer ${token}`,
    });

    yield put(actions.loadedPersonsByPhoneNumber({ response }));
  } catch (error) {
    yield put(actions.errorLoadingPersonsByPhoneNumber());
  }
}

export function* getRelatedPlans(action) {
  const { query, token } = action.payload;

  yield put(actions.loadingRelatedPlans());

  try {
    const response = yield call(NextServerAPI, {
      method: 'GET',
      path: `/api/products/plans/?${qs.stringify(query)}`,
      AuthorizationToken: `bearer ${token}`,
    });

    yield put(actions.loadedRelatedPlans({ response }));
  } catch (error) {
    yield put(actions.errorLoadingRelatedPlans());
  }
}

export default [
  [actionTypes.GET_QUOTATION, getQuotation],
  [actionTypes.SUBMIT_QUOTATION, submitQuotation],
  [actionTypes.GET_PLAN_AND_PRODUCT, getPlanAndProduct],
  [actionTypes.GET_BENEFITS_AND_TSI, getBenefitsAndTsi],
  [actionTypes.GET_QUOTATION_HISTORY, getQuotationHistory],
  [actionTypes.GET_EXISTING_MEMBER, getExistingMember],
  [actionTypes.GET_PLAN, getPlan],
  [actionTypes.GET_RELATED_PLANS, getRelatedPlans],
  [actionTypes.GET_RELATED_PLANS_WITH_QUOTATION_ID, getRelatedPlansWithQuotationId],
  [actionTypes.GET_USER_PROFILE, getUserProfile],
  [actionTypes.DELETE_MEMBER, deleteMember],
  [actionTypes.UPDATE_STATUS, updateStatus],
  [actionTypes.GET_EXISTING_USERS, getExistingUsers],
  [actionTypes.SAVE_PERSON, savePerson],
  [actionTypes.GET_PERSONS_BY_PHONE_NUMBER, getPersonsByPhoneNumber],
];

import { actionTypes } from "../../../shell/actions";

const deafultState = {
  loading: false,
  error: false,
  brands: [],
  models: [],
  types: [],
  years: [],
  productBrands: [],
  planList: [],
  planTotalPages: '',
  compareList: [],
  planProviderData: [],
  benefitGroups: [],
  providerActiveData: [],
  autoRangePrice: {},
  isRangePriceReverse: false,
  errorMessage: '',
  shouldLazyLoad: false,
  additionalBenefits: [],
  additionalBenefitGroups: [],
  plan: {},
  insuranceProviders: [],
  nearbyProviders: [],
  totalProviders: 0,
  bpjsProduct: {},
  plnProducts: [],
  brandsConfig: {},
};

function normalizeAdditionalBenefitGroups(dataList = []) {
  const additionalBenefitGroups = [];

  dataList.forEach(data => {
    data.additional_benefit.forEach(benefit => {
      const found = additionalBenefitGroups.find(d => d === benefit.name);
      if (!found) {
        additionalBenefitGroups.push(benefit.name);
      }
    })
  });

  return additionalBenefitGroups;
}

function normalizeBenefitGroups(dataList = []) {
  let benefitCategories = [];
  let featureList = [];

  dataList.forEach((row) => {
    row.benefit_groups.map((benefit) => {
      let foundIndex = benefitCategories.findIndex(category => category.title === benefit.name);
      if (foundIndex != -1) {
        benefit.benefits.map((item) => {
          if (!benefitCategories[foundIndex].benefits.find(row => row.title === item.name)) {
            benefitCategories[foundIndex].benefits.push({
              ...item,
              title: item.name
            });
          }
        })
      }
      else {
        benefitCategories.push({
          title: benefit.name,
          description: benefit.description,
          ordering: benefit.ordering,
          benefits: benefit.benefits.map(row => ({
            ...row,
            title: row.name
          }))
        })
      }
    })
  });

  benefitCategories.sort((a, b) => a.ordering - b.ordering);

  dataList.map((row) => {
    Object.keys(row.product.attributes).forEach(attr => {
      if (!featureList.find(item => item.title === attr)) {
        featureList.push({
          title: attr,
          description: attr
        });
      }
    });
  });

  benefitCategories.push({
    title: 'Fitur',
    description: 'Fitur',
    benefits: featureList
  });

  return benefitCategories;
}

function getReverseStatusRangePrice(dataList = []) {
  const [rangePrice] = dataList;
  if (rangePrice.lower_price > rangePrice.upper_price) {
    return true;
  }

  return false;
}

function normalizeRangePrice(dataList = []) {
  const [rangePrice] = dataList;
  const copyRangePrice = Object.assign({}, rangePrice);

  switch (true) {
    case rangePrice.upper_price === 0:
    case rangePrice.lower_price === rangePrice.upper_price:
      copyRangePrice.upper_price = rangePrice.lower_price + (rangePrice.lower_price * 20 / 100);
      break;
    case rangePrice.lower_price > rangePrice.upper_price:
      copyRangePrice.upper_price = rangePrice.lower_price;
      copyRangePrice.lower_price = rangePrice.upper_price;
      break;
  }

  copyRangePrice.lower_price = parseInt(copyRangePrice.lower_price);
  copyRangePrice.upper_price = parseInt(copyRangePrice.upper_price);

  return copyRangePrice;
}

function normalizeAndSortLocation(dataList = []) {
  return normalizeResolvedDataArray(dataList)
    .map(row => {
      row = row.sort((a, b) => a.distance - b.distance)
      return row;
    });
}

function normalizeResolvedDataArray(dataList = []) {
  return dataList.map(row => row.data);
}

function normalizeDataList(dataList = []) {
  return dataList.map(row => ({ label: row.name, value: row.id }));
}

function normalizeYearList(dataList = []) {
  return dataList.map(row => ({ label: row.year, value: row.id }));
}

function checkingPremiumZero(dataList = []) {
  return dataList.filter((row) => row.premium !== 0);
}

function filterPlanList(dataList = [], compareList = []) {
  return dataList.map(row => {
    if (row && row.premium !== 0) {
      const compareItem = compareList.find(item => item.id === row.id);

      if (compareItem) {
        return {
          ...row,
          isSelected: true,
          category: compareItem.category || null,
        }
      }
      else {
        return {
          ...row,
          isSelected: false,
          category: null,
        }
      }
    }
  });
}
/**
 *
 * @param {Object} state - Redux state
 * @param {Object} response - server response
 */

export default function plansReducers(state = deafultState, action) {
  switch (action.type) {
    case actionTypes.LOADING_AUTO_BRANDS:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOADED_AUTO_BRANDS:
      return {
        ...state,
        brands: normalizeDataList(action.payload.response.data),
        loading: false
      };
    case actionTypes.LOADING_AUTO_MODELS:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOADED_AUTO_MODELS:
      return {
        ...state,
        models: normalizeDataList(action.payload.response.data),
        loading: false
      };
    case actionTypes.LOADING_AUTO_TYPES:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOADED_AUTO_TYPES:
      return {
        ...state,
        types: normalizeDataList(action.payload.response.data),
        loading: false
      };
    case actionTypes.LOADING_AUTO_YEARS:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOADED_AUTO_YEARS:
      return {
        ...state,
        years: normalizeYearList(action.payload.response.data),
        loading: false
      };
    case actionTypes.LOADING_BRANDS:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOADED_BRANDS:
      return {
        ...state,
        productBrands: normalizeDataList(action.payload.response.data),
      };
    case actionTypes.LOADING_BRANDS_CONFIG:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOADED_BRANDS_CONFIG:
      return {
        ...state,
        brandsConfig: action.payload.response.data,
      };
    case actionTypes.RESET_PLANS:
      return {
        ...state,
        planList: [],
      };
    case actionTypes.LOADING_PLANS:
      return {
        ...state,
        loading: state.planList.length ? false : true,
        shouldLazyLoad: state.planList.length ? true : false,
      };
    case actionTypes.LOADED_PLANS:
      let planList = [...state.planList];
      planList = planList.concat(action.payload.response.data.plans);
      const checkedPlansWithPremiumZero = checkingPremiumZero(planList)

      return {
        ...state,
        planList: filterPlanList(checkedPlansWithPremiumZero, state.compareList),
        planTotalPages: action.payload.response.data.pages,
        loading: false,
        shouldLazyLoad: false,
      };
    case actionTypes.RESET_COMPARE_PLANS:
      return {
        ...state,
        compareList: [],
      };
    case actionTypes.ERROR_LOADING_PLANS:
      return {
        ...state,
        loading: false,
        shouldLazyLoad: false,
        errorMessage: action.payload.errorMessage,
      };
    case actionTypes.UPDATE_PLAN_COMPARE_STATUS:
      return {
        ...state,
        planList: filterPlanList(action.payload.planList, action.payload.compareList),
        compareList: action.payload.compareList
      };
    case actionTypes.LOADING_PLANS_AND_PROVIDERS:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOADED_PLANS_AND_PROVIDERS:
      return {
        ...state,
        loading: false,
        planList: filterPlanList(normalizeResolvedDataArray(action.payload.plans), normalizeResolvedDataArray(action.payload.plans)),
        compareList: normalizeResolvedDataArray(action.payload.plans),
        planProviderData: normalizeResolvedDataArray(action.payload.providers),
        benefitGroups: normalizeBenefitGroups(normalizeResolvedDataArray(action.payload.plans)),
        additionalBenefitGroups: normalizeAdditionalBenefitGroups(normalizeResolvedDataArray(action.payload.plans)),
        nearbyProviders: action.payload.nearbyProviders ? normalizeAndSortLocation(action.payload.nearbyProviders) : [],
      };
    case actionTypes.LOADING_INSURANCE_PROVIDERS:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOADED_INSURANCE_PROVIDERS:
      return {
        ...state,
        planProviderData: normalizeResolvedDataArray(action.payload.response),
        benefitGroups: normalizeBenefitGroups(state.compareList),
        loading: false
      };
    case actionTypes.LOADING_INSURANCE_PROVIDERS_BY_CHARACTER:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOADING_AUTO_RANGE_PRICE:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOADED_AUTO_RANGE_PRICE:
      return {
        ...state,
        autoRangePrice: normalizeRangePrice(action.payload.response.data),
        isRangePriceReverse: getReverseStatusRangePrice(action.payload.response.data),
        loading: false
      };
    case actionTypes.ERROR_LOADING_AUTO_NAME_PRICE:
      return {
        ...state,
        error: true,
      };
    case actionTypes.LOADING_ADDITIONAL_BENEFITS:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOADED_ADDITIONAL_BENEFITS:
      return {
        ...state,
        additionalBenefits: action.payload.additionalBenefits.data,
        loading: false
      };
    case actionTypes.ERROR_LOADING_ADDITIONAL_BENEFITS:
      return {
        ...state,
        error: true,
        loading: false
      };
    case actionTypes.LOADING_PLAN_AND_INSURANCE_PROVIDERS:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOADED_PLAN_AND_INSURANCE_PROVIDERS:
      return {
        ...state,
        plan: action.payload.plan.data,
        providerActiveData: action.payload.insuranceProviders.data.insuranceProviders,
        totalProviders: action.payload.insuranceProviders.data.insuranceProviders.length,
        loading: false,
      };
    case actionTypes.ERROR_LOADING_PLAN_AND_INSURANCE_PROVIDERS:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case actionTypes.RESET_NEARBY_PROVIDERS:
      return {
        ...state,
        nearbyProviders: [],
      };
    case actionTypes.LOADING_BPJS_PRODUCT:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOADED_BPJS_PRODUCT:
      return {
        ...state,
        bpjsProduct: action.payload.response.data.length > 0 ? action.payload.response.data[0] : {},
        loading: false,
      };
    case actionTypes.ERROR_LOADING_BPJS_PRODUCT:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case actionTypes.LOADING_PLN_PRODUCT:
      return {
        ...state,
        loading: true
      };
    case actionTypes.LOADED_PLN_PRODUCT:
      return {
        ...state,
        plnProducts: action.payload.response.data,
        loading: false,
      };
    case actionTypes.ERROR_LOADING_PLN_PRODUCT:
      return {
        ...state,
        error: true,
        loading: false,
      };
    default:
      return state;
  }
}

import commonSagas from '../common/sagas';
import accountSagas from '../modules/account/sagas';
import agentSagas from '../modules/agents/sagas';
import planSagas from '../modules/plans/sagas';
import quotationSagas from '../modules/quotations/sagas';
import orderSagas from '../modules/orders/sagas';
import digitalProductSagas from '../modules/digital-product/sagas';
import notificationSagas from '../modules/notification/sagas';

export default [
  ...commonSagas,
  ...accountSagas,
  ...agentSagas,
  ...planSagas,
  ...quotationSagas,
  ...orderSagas,
  ...digitalProductSagas,
  ...notificationSagas,
];
